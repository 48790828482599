import { Button } from '@mui/material';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link, useLocation } from 'react-router-dom';
import { UserAuth } from './context/AuthContext';
import './Sidebar.css';

export default props => {
    const [isOpen, setOpen] = useState(false);


    const handleIsOpen = () => {
        setOpen(!isOpen)
      }
    
      const closeSideBar = () => {
        setOpen(false)
      }

    const { user, logOut } = UserAuth();

    const handleSignOut = async () => {
        
    localStorage.setItem('userData', '112511920029842415746');
    localStorage.removeItem('userData_name');
    localStorage.removeItem('userData_email');

    try {
        await logOut();
    } catch (error) {
        console.log(error)
    }
    }

  return (
        <Menu
        isOpen={isOpen}
        onOpen={handleIsOpen}
        onClose={handleIsOpen}
        >
          {/* <h1 className='text-left text-1xl font-bold' style={{color:'white', textDecoration:'none',margin:10, fontSize:28, overflow:'hidden'}}>Menu</h1> */}
          <Link className='text-left text-1xl font-bold menu-item' to='/main' onClick={closeSideBar}>Recommendations</Link>
          <Link className='text-left text-1xl font-bold menu-item' to='/account' onClick={closeSideBar}>Watched</Link>
          <Link className='text-left text-1xl font-bold menu-item' to='/statistics' onClick={closeSideBar}>Statistics</Link>
          <Link className='text-left text-1xl font-bold menu-item' to='/search' onClick={closeSideBar}>Search</Link>
          {/* <Link className='text-left text-1xl font-bold menu-item' to='/feedback' onClick={closeSideBar}>Feedback</Link> */}
          <Link className='text-left text-1xl font-bold menu-item' to='/about' onClick={closeSideBar}>About</Link>
          {/* <Link className='text-left text-1xl font-bold menu-item' to='/developer' onClick={closeSideBar}>Developer</Link> */}

          <Link className='text-left text-1xl font-bold menu-item' to='/signin' onClick={closeSideBar}>Sign In</Link>
          <Link className='text-left text-1xl font-bold menu-item' to='/signin' onClick={()=>{closeSideBar();handleSignOut()}}>Logout</Link>
      </Menu>
  );
};