import { addDoc, collection, collectionGroup, doc, getDocs, limit, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import React from 'react';
import {firestore} from '../firebase'
import { UserAuth } from '../context/AuthContext';
import Cookies from 'js-cookie';
import similarity from 'compute-cosine-similarity'
import { Stack, ImageList, ImageListItem, Button, h1Field } from '@mui/material'
import Blur from 'react-css-blur'
import noimageImage from '../.assets/noimage.gif'

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { ThreeCircles } from 'react-loader-spinner';
import ReactToolTip from 'react-tooltip'
const axios = require('axios').default;

type State = {
  loading?:string;
  thisUser?:any;
  width?:number;
  height:number;
}

class About extends React.Component<{}, State> {
  constructor(props){
    super(props);

    this.state={
      loading:'Hello',
      thisUser:{},
      width:window.innerWidth,
      height:window.innerHeight,
    }
  }

  async componentDidMount(): void {
    window.addEventListener('resize', this.updateDimensions);

    this.setState({loading:false})

  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight});
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render(): React.ReactNode {
      if (!this.state.loading){
        return (
        <div style={{overflow:'hidden'}}>
          <div style={{
            backgroundColor:'black',
            zIndex:-1,
            overflow:'hidden',
            justifyContent:'center',
            objectFit:'fill',
            height:'200%', 
            width:'200%',
            objectPosition:'center',
            position:'fixed',
          }}>
          <Blur radius={(this.state.height>this.state.width)?'40px':'60px'} transition="1ms">
          <img
          src={'https://image.tmdb.org/t/p/w500/pP27zlm9yeKrCeDZLFLP2HKELot.jpg'}
          height={'100%'}
          style={{objectFit:'fill', objectPosition:'center', overflow:'hidden'}}
          width={'100%'}
          />
          </Blur>
          </div>

          <div style={{width:this.state.width*0.95, marginTop:80, height:this.state.height, overflow:'hidden', marginLeft:(this.state.width/2)-(this.state.width*0.95/2)}}>
            <h1 style={{color:'white', width:'100%', textAlign:'center', fontWeight:'bold',fontSize:22, marginTop:15, overflow:'hidden'}}>How It Works</h1>
            <h1 style={{color:'white', width:'100%', textAlign:'center', fontSize:18, overflow:'hidden'}}>TheMovieWay is not your average recommendation system. The website recommends movies and shows based solely on other user's rating and your similarity to them. The more movies and shows you rate the better your recommendations will be and rating honestly is important.</h1>

            <h1 style={{color:'white', width:'100%', textAlign:'center', fontWeight:'bold',fontSize:22, marginTop:15, overflow:'hidden'}}>Getting Started</h1>
            <h1 style={{color:'white', width:'100%', textAlign:'center', fontSize:18, overflow:'hidden'}}>To start getting recommendations on your recommendations page simply add a movie and a show and give them a rating, then come back. From the recommendations page you can begin adding everything you've watched or go to the Search page to add a specific movie or show. Your Account page is where you can view all your added movies and shows and update or remove them.</h1>
                
            <div style={{marginTop:80}}>
              <h1 style={{color:'white', width:'100%', textAlign:'center', fontWeight:'bold',fontSize:22, overflow:'hidden'}}>Disclaimer</h1>
              <h1 style={{color:'white', width:'100%', textAlign:'center', fontSize:18, overflow:'hidden'}}>This product uses the TMDb API but is not endorsed or certified by TMDb.</h1>
            </div>

          </div>

        </div>

      );
  }else{
    return (
      <div style={{width:this.state.width, position:'absolute',height:this.state.height, opacity:0.6, zIndex:2, backgroundColor:'black'}}>
      <ThreeCircles
          height={this.state.width/8}
          width={this.state.width/8}
          radius = "9"
          color = '#1167b1ff'
          ariaLabel = 'three-dots-loading'
          wrapperStyle={{marginTop:this.state.height/2-this.state.width/8/2, zIndex:3, marginLeft:this.state.width/2-this.state.width/8/2, position:'absolute'}}
        />
      </div>  
        )
      }
    }

  };

export default About;
