import { Button } from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import Cookies from 'js-cookie';
import { slide as Menu } from 'react-burger-menu'
import menuicon from '../.assets/logo.png'
import { height } from '@mui/system';
import '../Sidebar.css';

import Sidebar from '../Sidebar';

const Navbar = () => {

  return (
      <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
  );
};

export default Navbar;
