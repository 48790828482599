// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyANYzl8bPI3gO2-ALWx5X7fKxefQM-Jzqg",
  authDomain: "movieapp-b3ddf.firebaseapp.com",
  projectId: "movieapp-b3ddf",
  storageBucket: "movieapp-b3ddf.appspot.com",
  messagingSenderId: "262980596823",
  appId: "1:262980596823:web:a243aa6dfeb3255ea75f12",
  measurementId: "G-SC1ZRG90Y6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
