import { addDoc, collection, collectionGroup, doc, getDocs, limit, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import React from 'react';
import {firestore} from '../firebase'
import { UserAuth } from '../context/AuthContext';
import Cookies from 'js-cookie';
import similarity from 'compute-cosine-similarity'
import { Stack, ImageList, ImageListItem, Button, colors } from '@mui/material'
import Blur from 'react-css-blur'
import { Audio, BallTriangle, Bars, Circles, CirclesWithBar, Grid, Hearts, Oval, Puff, TailSpin, ThreeCircles, ThreeDots } from  'react-loader-spinner'
import Modal from 'react-modal';
import Popup from 'reactjs-popup';

import Feedback from './Feedback.tsx'

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import nextIcon from '../.assets/next.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import axios from 'axios';
import { faLeftRight } from '@fortawesome/free-solid-svg-icons';

type State = {
  loading?:string;
  thisUser?:any;

  movies?:object;
  displayedMovies?:object;
  bestUserMovies?:object;
  currentBestUserMovieIndex?:number;
  currentMovieIndex?:number;
  bestMovieMatch?:number;

  shows?:object;
  displayedShows?:object;
  bestUserShows?:object;
  currentBestUserShowIndex?:number;
  currentShowIndex?:number;
  bestShowMatch?:number;

  sliderValue?:number;
  sliding?:boolean;
  ratingDescriptor?:string;
  showModal?:boolean;
  width?:number;
  height?:number;
  popupWidth?:number;
  popupHeight?:number;
  showBestUser?:boolean;
  view?:string;

  myMovies?:object;
  myShows?:object;
}

export function getColor(pct) {
  var percentColors = [
    { pct: 0.0, color: { r: 0xde, g: 0x37, b: 0x00 } },
    { pct: 0.5, color: { r: 0xff, g: 0x83, b: 0x03 } },
    { pct: 1.0, color: { r: 0x2a, g: 0xa1, b: 0x0f } } ];

    for (var i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
          break;
      }
    }
    var lower = percentColors[i - 1];
    var upper = percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return rgbToHex(color.r, color.g, color.b)
    //return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
    // or output as hex if preferred
}
function rgbToHex(red, green, blue) {
  const rgb = (red << 16) | (green << 8) | (blue << 0);
  return '#' + (0x1000000 + rgb).toString(16).slice(1);
}

class Main extends React.Component<{}, State> {
  constructor(props){
    super(props);

    this.state={
      loading:true,
      thisUser:{},

      movies:[],
      displayedMovies:[],
      bestUserMovies:[],
      currentMovieIndex:0,
      currentBestUserMovieIndex:0,
      bestMovieMatch:0,

      shows:[],
      displayedShows:[],
      bestUserShows:[],
      currentShowIndex:0,
      currentBestUserShowIndex:0,
      bestShowMatch:0,

      sliderValue:5,
      sliding:false,
      ratingDescriptor:'',
      showModal:false,
      width:window.innerWidth,
      height:window.innerHeight,
      showBestUser:false,
      popupWidth:(window.innerWidth>window.innerHeight)?window.innerWidth/4:window.innerWidth*0.9,
      popupHeight:((window.innerWidth>window.innerHeight)?window.innerWidth/4:window.innerWidth*0.9)/2,
      view:'movie',

      myMovies:[],
      myShows:[],
    }
  }

  async componentDidMount(): void {
    window.addEventListener('resize', this.updateDimensions);
    const userId = localStorage.getItem('userData');

    if (userId == 'undefined' || userId == '112511920029842415746' || userId == null || userId == undefined){
      localStorage.setItem('userData', '112511920029842415746');
      await this.getRecommendations();
      //await this.getWildcard('movie',8);

    }else{
      await this.getRecommendations();
      //await this.getWildcard('movie',8);

    }


    this.setState({loading:false})
  }


  async getWildcards(type, items, rating){
    var sumOfAccuracies = 0;
    var numberOfAccuracies = 0;

    var genreCountList = [];
    for (const item of items){
      const accuracy = Math.min(parseFloat(item.data.predicted), parseFloat(item.data.rating))/Math.max(parseFloat(item.data.predicted), parseFloat(item.data.rating))
      if (accuracy){
        sumOfAccuracies += accuracy
        numberOfAccuracies++;
      }
      if (item.data.genres!=undefined){
        for (var genre of item.data.genres){
          if (genreCountList.includes(genre)){
            //UPDATE GENRE
            const foundGenre = genreCountList.find(g=>g.genreId==genre);
            genreCountList[genreCountList.indexOf(foundGenre)].totalGenrePoints += item.data.rating;
            genreCountList[genreCountList.indexOf(foundGenre)].genreCount += 1;
          }else{
            //ADD GENRE
            genreCountList.push({
              genreId:genre,
              totalGenrePoints:item.data.rating,
              genreCount:1,
            })
          }
        }
      }
    }

    genreCountList = genreCountList.sort(function(a, b) {
      return parseFloat(a.totalGenrePoints/a.genreCount) - parseFloat(b.totalGenrePoints/b.genreCount)
    }).reverse();

    const bestGenres = `${genreCountList[0].genreId},${genreCountList[1].genreId},${genreCountList[2].genreId}`;
    const worstGenres = `${genreCountList[genreCountList.length-1].genreId},${genreCountList[genreCountList.length-2].genreId},${genreCountList[genreCountList.length-3].genreId}`;

    const accuracy = (Math.round((sumOfAccuracies/numberOfAccuracies)* 100) / 1);

    var wildcards = []

    try{
      const result = await axios.get(`https://api.themoviedb.org/3/discover/${type}?api_key=56746fb604baa81be557f341f2ec2f83&page=1&with_original_language=en&without_genres=${worstGenres}&vote_count.gte=1000`);
      wildcards = result.data.results;
      console.log(wildcards)
    }catch (error){
      console.log(error)
    }

    return {
      wildcards:wildcards,
      accuracy:accuracy
    };
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight});
    this.setState({popupWidth:(window.innerWidth>window.innerHeight)?window.innerWidth/4:window.innerWidth*0.9, popupHeight:((window.innerWidth>window.innerHeight)?window.innerWidth/4:window.innerWidth*0.9)/2})
    // alert('window updated')
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  
  async getRecommendations(){
    await this.getThisUser();
    await this.getMovieRecommendations();
    await this.getShowRecommendations();

    this.setState({loading:false})

  }

  async getThisUser(){
    //GET THIS USER'S FIREBASE DOCUMENT
    const userId = localStorage.getItem('userData');
    const thisUserRef = collection(firestore, "Users");
    const thisUserQuery = query(thisUserRef, where('id', '==',userId));
    const thisUserSnapshot = await getDocs(thisUserQuery);
    const thisUser = thisUserSnapshot.docs[0].data();
    this.setState({thisUser:thisUser})
  }

  async getMovieRecommendations(){
    //GET THIS USER'S FIREBASE DOCUMENT
    const userId = localStorage.getItem('userData');
    const thisUserRef = collection(firestore, "Users");
    const thisUserQuery = query(thisUserRef, where('id', '==',userId));
    const thisUserSnapshot = await getDocs(thisUserQuery);
    const thisUser = thisUserSnapshot.docs[0].data();

    var allMovies = []

    if (thisUser.readMovies != undefined && thisUser.readMovies.length>0){
      //console.log('FIREBASE READ MOVIES EXISTS')
      //GET ALL MOVIES THAT HAVE CHANGGED SINCE THIS USERS'S LAST READ FROM FIREBASE
      if (Array.isArray(thisUser.readMovies)){
        for (const chunk of thisUser.readMovies){
          allMovies = allMovies.concat(JSON.parse(chunk));
        }
      }else{
        allMovies = JSON.parse(thisUser.readMovies)
      }

      const changedMoviesRef = collectionGroup(firestore, "movie");

      const changedMoviesQuery = query(changedMoviesRef, where('lastModified', '>', Date.parse(thisUser.lastMoviesRead)));

      const changedMoviesSnapshot = await getDocs(changedMoviesQuery);

      changedMoviesSnapshot.forEach((doc) => {
        const itemToPush = {userId:doc.ref.parent.parent.id, data:doc.data()}
        const itemInReadMovies = allMovies.find(i=>i.data.id.toString()===itemToPush.data.id.toString() && i.userId===itemToPush.userId);

        //REMOVE ITEM IN READ
        if (itemInReadMovies){
          allMovies = allMovies.filter(i=>i!=itemInReadMovies);

        }
        //PUSH NEW VERSION
        allMovies.push(itemToPush);
      });
    }else{
      // console.log('could not get latest read user movies')
      // //NO LOCAL MOVIES EXIST SO GET ALL MOVIES FROM FIREBASE
      // const allMoviesRef = collectionGroup(firestore, "movie");

      // const allMoviesQuery = query(allMoviesRef);

      // const allMoviesSnapshot = await getDocs(allMoviesQuery);

      // allMoviesSnapshot.forEach((doc) => {
      //   const itemToPush = {userId:doc.ref.parent.parent.id, data:doc.data()}

      //   allMovies.push(itemToPush);
      // });
    }

    //RE UPDATE READ MOVIES
    var date = new Date();
    var dateStr =
    ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
    ("00" + date.getDate()).slice(-2) + "/" +
    date.getFullYear() + " " +
    ("00" + date.getHours()).slice(-2) + ":" +
    ("00" + date.getMinutes()).slice(-2) + ":" +
    ("00" + date.getSeconds()).slice(-2);

    const chunkSize = 200;
    const chunks = [];
    for (let i = 0; i < allMovies.length; i += chunkSize) {
        const chunk = allMovies.slice(i, i + chunkSize);
        chunks.push(JSON.stringify(chunk))
    }

    const updateMyReadMoviesRef = collection(firestore, 'Users');
    await updateDoc(doc(updateMyReadMoviesRef, thisUser.id), {
      readMovies:chunks,
      lastMoviesRead:dateStr
    });

    //COSINE ALGORITHM ON ALL MOVIES
    //SPLIT MOVIES INTO MINE AND OTHER
    const myMovies = this.deepClone(allMovies).filter(b=>b.userId==thisUser.id).filter(c=>c.data.deleted==false);
    this.setState({myMovies:myMovies})
    const otherMovies = this.deepClone(allMovies).filter(a => !myMovies.map(b=>b.userId).includes(a.userId)).filter(c=>c.data.deleted===false).filter(c=>parseFloat(c.data.rating)>0);

    //GROUP DATA BY USER
    const groupedData = otherMovies.reduce((hash, obj) => ({...hash, [obj['userId']]:( hash[obj['userId']] || [] ).concat(obj)}), {})

    //RESET FINAL MOVIE RECOMMENDATIONS
    var finalMovieRecommendations = [];

    if (myMovies.length>0){
      //LOOP THROUGH GROUPED DATA - FOR EACH USER
      var bestUserId = 0;
      var bestUserMatch = 0;

      for (let userIndex in groupedData) {
        //RESET MY MOVIE RATINGS AND OTHER USER RATINGS
        const myMovieRatings = [];
        const otherUserMovieRatings = [];
        
        //LOOP THROUGH MOVIES FROM THE USER
        for (let movieIndex in groupedData[userIndex]){
          //SET MOVIE
          const movie = groupedData[userIndex][movieIndex];

          //CHECK IF MOVIE IS IN COMMON
          const compareMovie = myMovies.find(o => o.data.id.toString() === movie.data.id.toString());
          if (compareMovie!==undefined){
            if (parseFloat(compareMovie.data.rating) == 0){
              groupedData[userIndex] = (groupedData[userIndex]).filter(m=>m!==compareMovie)
            }else{
              //ADD EACH RAATING TO THEIR LIST
              myMovieRatings.push(compareMovie.data.rating)
              otherUserMovieRatings.push(movie.data.rating)
            }
          }
        }
        //CALCULATE FINAL MOVIE MATCH
        var finalMovieMatch = similarity(myMovieRatings,otherUserMovieRatings)
        if (finalMovieMatch === null || isNaN(finalMovieMatch)){
          finalMovieMatch = 0;
        }

        if (parseFloat(finalMovieMatch) > 0){
        //CREATE A LIST OF MOVIE RECOMMENDATIONS (NON COMMON MOVIES)
        const movieRecommendations = (groupedData[userIndex]).filter(a => !myMovies.map(b=>b.data.id.toString()).includes(a.data.id.toString()))
       
        const editedMatch = finalMovieMatch*((groupedData[userIndex].length-movieRecommendations.length)/myMovies.length)
        if (parseFloat(editedMatch) > bestUserMatch && movieRecommendations.length>0){
          bestUserId = groupedData[userIndex][0].userId;
          bestUserMatch = editedMatch;
        }
        //LOOP THROUGH MOVIE OF RECOMMENDATIONS
        for (const movie of movieRecommendations){
          //CHECK IF MOVIE IS ALREADY IN FINAL RECOMMENDATIONS
          const movieInFinalRecommendations = finalMovieRecommendations.find((o => o.data.id.toString() === movie.data.id.toString()));

          const objIndex = movieRecommendations.findIndex((obj => obj.data.id.toString() === movie.data.id.toString()));

          movieRecommendations[objIndex].data.rating = parseFloat(movie.data.rating)*parseFloat(finalMovieMatch);

          if (movieInFinalRecommendations===undefined){
            movie.data.similarities = [finalMovieMatch];
            movie.data.finalRating = this.deepClone(movie.data.rating);

            finalMovieRecommendations.push(this.deepClone(movie))
          }else{
            movieInFinalRecommendations.data.similarities.push(finalMovieMatch)
            movieInFinalRecommendations.data.rating = movie.data.rating + movieInFinalRecommendations.data.rating;                   
  
            const sum = (movieInFinalRecommendations.data.similarities).reduce(function(pv, cv) { return pv + cv; }, 0);
            
            movieInFinalRecommendations.data.finalRating = movieInFinalRecommendations.data.rating/sum
          }
        }
        }
      }

      //INSERT WILDCARDS
      var wildcards = await this.getWildcards('movie', myMovies,8);
      const numberOfWildcards = Math.abs(wildcards.accuracy-100);

      for (var wildcard of wildcards.wildcards.slice(0,numberOfWildcards)){
        console.log(finalMovieRecommendations.find(i=>i.data.id == wildcard.id))

        if (finalMovieRecommendations.find(i=>i.data.id == wildcard.id) !=undefined || myMovies.find(i=>i.data.id == wildcard.id) !=undefined){
          // wildcards = wildcards.filter(i=>i!=wildcard);
          console.log('Already Exists')
        }else{
          wildcard.data = {};
          wildcard.data.finalRating = wildcard.vote_average;
          wildcard.data.image = "https://image.tmdb.org/t/p/w500"+wildcard.poster_path;
          wildcard.data.isWildcard = true;
          wildcard.data.id = wildcard.id;
          wildcard.data.tmdbRating = wildcard.vote_average;
          wildcard.data.title = wildcard.original_title;

          console.log(wildcard)

          //INSERT WILDCARD
          finalMovieRecommendations.push(wildcard)
        }
      }

      finalMovieRecommendations = finalMovieRecommendations
      //.filter(o=>o.data.similarities.length>1)
      .sort(function(a, b) {
        return parseFloat(a.data.finalRating) - parseFloat(b.data.finalRating);
      }).reverse();

      const bestUserMovies = (this.deepClone(allMovies))
      .filter(b=>b.userId==bestUserId)
      .filter(c=>c.data.deleted==false)
      .filter(c=>parseFloat(c.data.rating)>0)
      .filter(a => !myMovies.map(b=>b.data.id.toString()).includes(a.data.id.toString()))
      .sort(function(a, b) {
        return parseFloat(a.data.rating) - parseFloat(b.data.rating);
      }).reverse();

      // const bestUserMovies = otherMovies
      //   .filter(o=>o.userId==bestUserId)
      //   .sort(function(a, b) {
      //     return parseFloat(a.data.rating) - parseFloat(b.data.rating);
      //   }).reverse();
  
      console.log('SUCCESS')
      this.setState({movies:finalMovieRecommendations, bestUserMovies:bestUserMovies, bestMovieMatch:bestUserMatch*100});

    }else{
      console.log('This user has no movies')
    }

  }

  async getShowRecommendations(){
    const userId = localStorage.getItem('userData');
    const thisUserRef = collection(firestore, "Users");
    const thisUserQuery = query(thisUserRef, where('id', '==',userId));
    const thisUserSnapshot = await getDocs(thisUserQuery);
    const thisUser = thisUserSnapshot.docs[0].data();
    
    var allShows = []

    if (thisUser.readShows != undefined && thisUser.readShows.length>0){
      //console.log('FIREBASE READ SHOWS EXISTS')
      //GET ALL SHOWS THAT HAVE CHANGGED SINCE THIS USERS'S LAST READ FROM FIREBASE
      if (Array.isArray(thisUser.readShows)){
        for (const chunk of thisUser.readShows){
          allShows = allShows.concat(JSON.parse(chunk));
        }
      }else{
        allShows = JSON.parse(thisUser.readShows)
      }

      const changedShowsRef = collectionGroup(firestore, "tv");

      const changedShowsQuery = query(changedShowsRef, where('lastModified', '>', Date.parse(thisUser.lastShowsRead)));

      const changedShowsSnapshot = await getDocs(changedShowsQuery);

      changedShowsSnapshot.forEach((doc) => {
        const itemToPush = {userId:doc.ref.parent.parent.id, data:doc.data()}
        const itemInReadShows = allShows.find(i=>i.data.id.toString()===itemToPush.data.id.toString() && i.userId===itemToPush.userId);

        //REMOVE ITEM IN READ
        if (itemInReadShows){
          allShows = allShows.filter(i=>i!=itemInReadShows);

        }
        //PUSH NEW VERSION
        allShows.push(itemToPush);
      });
    }else{
      // console.log('could not get latest read user shows')
      // //NO LOCAL SHOWS EXIST SO GET ALL SHOWS FROM FIREBASE
      // const allShowsRef = collectionGroup(firestore, "tv");

      // const allShowsQuery = query(allShowsRef);

      // const allShowsSnapshot = await getDocs(allShowsQuery);

      // allShowsSnapshot.forEach((doc) => {
      //   const itemToPush = {userId:doc.ref.parent.parent.id, data:doc.data()}

      //   allShows.push(itemToPush);
      // });
    }

    //RE UPDATE READ SHOWS
    var date = new Date();
    var dateStr =
    ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
    ("00" + date.getDate()).slice(-2) + "/" +
    date.getFullYear() + " " +
    ("00" + date.getHours()).slice(-2) + ":" +
    ("00" + date.getMinutes()).slice(-2) + ":" +
    ("00" + date.getSeconds()).slice(-2);


    const chunkSize = 200;
    const chunks = [];
    for (let i = 0; i < allShows.length; i += chunkSize) {
        const chunk = allShows.slice(i, i + chunkSize);
        chunks.push(JSON.stringify(chunk))
    }

    const updateMyReadShowsRef = collection(firestore, 'Users');
    await updateDoc(doc(updateMyReadShowsRef, thisUser.id), {
      readShows:chunks,
      lastShowsRead:dateStr
    });

    //COSINE ALGORITHM ON ALL SHOWS
    //SPLIT SHOWS INTO MINE AND OTHER
    const myShows = this.deepClone(allShows).filter(b=>b.userId==thisUser.id).filter(c=>c.data.deleted==false)
    const otherShows = this.deepClone(allShows).filter(a => !myShows.map(b=>b.userId).includes(a.userId)).filter(c=>c.data.deleted===false).filter(c=>parseFloat(c.data.rating)>0);

    //GROUP DATA BY USER
    const groupedData = otherShows.reduce((hash, obj) => ({...hash, [obj['userId']]:( hash[obj['userId']] || [] ).concat(obj)}), {})

    //RESET FINAL SHOW RECOMMENDATIONS
    var finalShowRecommendations = [];

    if (myShows.length>0){
      //LOOP THROUGH GROUPED DATA - FOR EACH USER
      var bestUserId = 0;
      var bestUserMatch = 0;
      for (let userIndex in groupedData) {
        //RESET MY SHOW RATINGS AND OTHER USER RATINGS
        const myShowRatings = [];
        const otherUserShowRatings = [];
        
        //LOOP THROUGH SHOWS FROM THE USER
        for (let showIndex in groupedData[userIndex]){
          //SET SHOW
          const show = groupedData[userIndex][showIndex];

          //CHECK IF SHOW IS IN COMMON
          const compareShow = myShows.find(o => o.data.id.toString() === show.data.id.toString());
          if (compareShow!==undefined){
            if (parseFloat(compareShow.data.rating) === 0){
              //console.log()
              groupedData[userIndex] = groupedData[userIndex].filter(m=>m!==compareShow)
            }else{
              //ADD EACH RAATING TO THEIR LIST
              myShowRatings.push(show.data.rating)
              otherUserShowRatings.push(compareShow.data.rating)
            }
          }
        }
        //CALCULATE FINAL SHOW MATCH
        var finalShowMatch = similarity(myShowRatings,otherUserShowRatings)
        if (finalShowMatch === null || isNaN(finalShowMatch)){
          finalShowMatch = 0;
        }

        if (parseFloat(finalShowMatch) > 0){
          //CREATE A LIST OF SHOW RECOMMENDATIONS (NON COMMON SHOWS)
          const showRecommendations = (groupedData[userIndex].filter(a => !myShows.map(b=>b.data.id.toString()).includes(a.data.id.toString())))
          const editedMatch = finalShowMatch*((groupedData[userIndex].length-showRecommendations.length)/myShows.length)
          if (parseFloat(editedMatch) > bestUserMatch && showRecommendations.length>0){
            bestUserId = groupedData[userIndex][0].userId;
            bestUserMatch = editedMatch;
          }

          //LOOP THROUGH SHOWS OF RECOMMENDATIONS
          for (const show of showRecommendations){
            //CHECK IF SHOW IS ALREADY IN FINAL RECOMMENDATIONS
            const showInFinalRecommendations = finalShowRecommendations.find((o => o.data.id.toString() === show.data.id.toString()));

            const objIndex = showRecommendations.findIndex((obj => obj.data.id.toString() === show.data.id.toString()));

            showRecommendations[objIndex].data.rating = parseFloat(show.data.rating)*parseFloat(finalShowMatch);

            if (showInFinalRecommendations===undefined){
              show.data.similarities = [finalShowMatch];
              show.data.finalRating = this.deepClone(show.data.rating);

              finalShowRecommendations.push(this.deepClone(show))
            }else{
              showInFinalRecommendations.data.similarities.push(finalShowMatch)
              showInFinalRecommendations.data.rating = show.data.rating + showInFinalRecommendations.data.rating;                   
    
              const sum = (showInFinalRecommendations.data.similarities).reduce(function(pv, cv) { return pv + cv; }, 0);
              
              showInFinalRecommendations.data.finalRating = showInFinalRecommendations.data.rating/sum
            }
          }
        }
      }
      //INSERT WILDCARDS
      var wildcards = await this.getWildcards('tv', myShows,8);
      const numberOfWildcards = Math.abs(wildcards.accuracy-100);

      for (var wildcard of wildcards.wildcards.slice(0,numberOfWildcards)){
        console.log(finalShowRecommendations.find(i=>i.data.id == wildcard.id))

        if (finalShowRecommendations.find(i=>i.data.id == wildcard.id) !=undefined || myShows.find(i=>i.data.id == wildcard.id) !=undefined){
          // wildcards = wildcards.filter(i=>i!=wildcard);
          console.log('Already Exists')
        }else{
          wildcard.data = {};
          wildcard.data.finalRating = wildcard.vote_average;
          wildcard.data.image = "https://image.tmdb.org/t/p/w500"+wildcard.poster_path;
          wildcard.data.isWildcard = true;
          wildcard.data.id = wildcard.id;
          wildcard.data.tmdbRating = wildcard.vote_average;
          wildcard.data.title = wildcard.original_name;

          console.log(wildcard)

          //INSERT WILDCARD
          finalShowRecommendations.push(wildcard)
        }
      }

      finalShowRecommendations = finalShowRecommendations
      //.filter(o=>o.data.similarities.length>1)
      .sort(function(a, b) {
        return parseFloat(a.data.finalRating) - parseFloat(b.data.finalRating);
      }).reverse();

      const bestUserShows = (this.deepClone(allShows))
      .filter(b=>b.userId==bestUserId)
      .filter(c=>c.data.deleted==false)
      .filter(c=>parseFloat(c.data.rating)>0)
      .filter(a => !myShows.map(b=>b.data.id.toString()).includes(a.data.id.toString()))
      .sort(function(a, b) {
        return parseFloat(a.data.rating) - parseFloat(b.data.rating);
      }).reverse();

  
      console.log('SUCCESS')
      this.setState({shows:finalShowRecommendations, bestUserShows:bestUserShows, bestShowMatch:bestUserMatch*100});

    }else{
      console.log('This user has no shows')
    }

  }

  deepClone(obj, hash = new WeakMap()) {
    // Do not try to clone primitives or functions
    if (Object(obj) !== obj || obj instanceof Function) return obj;
    if (hash.has(obj)) return hash.get(obj); // Cyclic reference
    try { // Try to run constructor (without arguments, as we don't know them)
        var result = new obj.constructor();
    } catch(e) { // Constructor failed, create object without running the constructor
        result = Object.create(Object.getPrototypeOf(obj));
    }
    // Optional: support for some standard constructors (extend as desired)
    if (obj instanceof Map)
        Array.from(obj, ([key, val]) => result.set(this.deepClone(key, hash), 
                                                   this.deepClone(val, hash)) );
    else if (obj instanceof Set)
        Array.from(obj, (key) => result.add(this.deepClone(key, hash)) );
    // Register in hash    
    hash.set(obj, result);
    // Clone and assign enumerable own properties recursively
    return Object.assign(result, ...Object.keys(obj).map (
        key => ({ [key]: this.deepClone(obj[key], hash) }) ));
  }

  getItemsToRender(){
    if (this.state.showBestUser){
      if (this.state.view=='movie'){
        if (this.state.bestUserMovies.length>0){
          const itemsToRender = [this.deepClone(this.state.bestUserMovies)[this.state.currentBestUserMovieIndex]];
          return itemsToRender;
        }else{
          return [];
        }
      }else{
        if (this.state.bestUserShows.length>0){
          const itemsToRender = [this.deepClone(this.state.bestUserShows)[this.state.currentBestUserShowIndex]];
          return itemsToRender;
        }else{
          return [];
        }
      }
    }else{
      if (this.state.view=='movie'){
        if (this.state.movies.length>0){
          const itemsToRender = [this.deepClone(this.state.movies)[this.state.currentMovieIndex]];
          return itemsToRender;
        }else{
          return [];
        }
      }else{
        if (this.state.shows.length>0){
          const itemsToRender = [this.deepClone(this.state.shows)[this.state.currentShowIndex]];
          return itemsToRender;
        }else{
          return [];
        }
      }
    }

  }

  nextClicked(){
    if (this.state.showBestUser){
      if (this.state.view=='movie'){
        if (this.state.currentBestUserMovieIndex < this.state.bestUserMovies.length-1){
          this.setState({currentBestUserMovieIndex:this.state.currentBestUserMovieIndex+1});
        }
      }else{
        if (this.state.currentBestUserShowIndex < this.state.bestUserShows.length-1){
          this.setState({currentBestUserShowIndex:this.state.currentBestUserShowIndex+1});
        }
      }
    }else{
      if (this.state.view=='movie'){
        if (this.state.currentMovieIndex < this.state.movies.length-1){
          this.setState({currentMovieIndex:this.state.currentMovieIndex+1});
        }
      }else{
        if (this.state.currentShowIndex < this.state.shows.length-1){
          this.setState({currentShowIndex:this.state.currentShowIndex+1});
        }
      }
    }


  }

  backClicked(){
    if (this.state.showBestUser){
      if (this.state.view=='movie'){
        if (this.state.currentBestUserMovieIndex > 0){
          this.setState({currentBestUserMovieIndex:this.state.currentBestUserMovieIndex-1});
        }
      }else{
        if (this.state.currentBestUserShowIndex > 0){
          this.setState({currentBestUserShowIndex:this.state.currentBestUserShowIndex-1});
    
        }
      }
    }else{
      if (this.state.view=='movie'){
        if (this.state.currentMovieIndex > 0){
          this.setState({currentMovieIndex:this.state.currentMovieIndex-1});
        }
      }else{
        if (this.state.currentShowIndex > 0){
          this.setState({currentShowIndex:this.state.currentShowIndex-1});
    
        }
      }
    }

  }

  async movieClicked(){
    if (localStorage.getItem('userData') == '112511920029842415746'){
      alert('Sign In To Use This Feature');
      this.setState({sliderValue:5,sliding:false,showModal:false})
    }else{
      this.backClicked();
      var date = new Date();
      var dateStr =
      ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
      ("00" + date.getDate()).slice(-2) + "/" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2) + ":" +
      ("00" + date.getSeconds()).slice(-2);

      if (this.state.showBestUser){
        if (this.state.view=='movie'){
          var item=this.state.bestUserMovies[this.state.currentBestUserMovieIndex];
           var genres = [];
           if (item.data.genres == undefined){
           }else{
             genres = item.data.genres;
           }
           var title = '';
           if (item.data.title == undefined){
           }else{
             title = item.data.title;
           }
           var tmdbRating = 0;
           if (item.data.tmdbRating == undefined){
             
           }else{
             tmdbRating = item.data.tmdbRating;
           }
           //UPDATE IN FIREBASE AND REMOVE FROM THIS MOVIES
           const addMovieRef = collection(firestore, 'Users/'+this.state.thisUser.id+'/movie');
           await setDoc(doc(addMovieRef, item.data.id.toString()), {
             id:item.data.id.toString(),
             image:item.data.image,
             rating:this.state.sliderValue,
             predicted:item.data.rating,
             lastModified:Date.parse(dateStr),
             genres:genres,
             title:title,
             deleted:false,
             tmdbRating:tmdbRating,
           });

           let newMovies = this.state.movies;
           newMovies = newMovies.filter(m=>m.data.id!=item.data.id);

           let newBestUserMovies = this.state.bestUserMovies;
           newBestUserMovies = newBestUserMovies.filter(m=>m!=item);

           this.setState({movies:newMovies, bestUserMovies:newBestUserMovies,sliderValue:5,sliding:false,showModal:false})
         }else{
           var item=this.state.bestUserShows[this.state.currentBestUserShowIndex];
           var genres = [];
           if (item.data.genres == undefined){
           }else{
             genres = item.data.genres;
           }
           var title = '';
           if (item.data.title == undefined){
           }else{
             title = item.data.title;
           }
           var tmdbRating = 0;
           if (item.data.tmdbRating == undefined){
   
           }else{
             tmdbRating = item.data.tmdbRating;
           }
           //UPDATE IN FIREBASE AND REMOVE FROM THIS MOVIES
           const addShowRef = collection(firestore, 'Users/'+this.state.thisUser.id+'/tv');
           await setDoc(doc(addShowRef, item.data.id.toString()), {
             id:item.data.id.toString(),
             image:item.data.image,
             rating:this.state.sliderValue,
             predicted:item.data.rating,
             lastModified:Date.parse(dateStr),
             genres:genres,
             title:title,
             deleted:false,
             tmdbRating:tmdbRating
           });
   
           this.nextClicked();
   
           let newBestUserShows = this.state.bestUserShows;
           newBestUserShows = newBestUserShows.filter(s=>s!=item);

           let newShows = this.state.shows;
           newShows = newShows.filter(s=>s.data.id!=item.data.id);
           this.setState({shows:newShows, bestUserShows:newBestUserShows, sliderValue:5,sliding:false,showModal:false})
         }
      }else{
        if (this.state.view=='movie'){
          var item=this.state.movies[this.state.currentMovieIndex];
           var genres = [];
           if (item.data.genres == undefined){
           }else{
             genres = item.data.genres;
           }
           var title = '';
           if (item.data.title == undefined){
           }else{
             title = item.data.title;
           }
           var tmdbRating = 0;
           if (item.data.tmdbRating == undefined){
             
           }else{
             tmdbRating = item.data.tmdbRating;
           }
           //UPDATE IN FIREBASE AND REMOVE FROM THIS MOVIES
           const addMovieRef = collection(firestore, 'Users/'+this.state.thisUser.id+'/movie');
           //alert(item.data.id)
           await setDoc(doc(addMovieRef, item.data.id.toString()), {
             id:item.data.id.toString(),
             image:item.data.image,
             rating:this.state.sliderValue,
             predicted:item.data.finalRating,
             lastModified:Date.parse(dateStr),
             genres:genres,
             title:title,
             deleted:false,
             tmdbRating:tmdbRating,
           });
           let newMovies = this.state.movies;
           newMovies = newMovies.filter(m=>m!=item);

           let newBestUserMovies = this.state.bestUserMovies;
           newBestUserMovies = newBestUserMovies.filter(m=>m.data.id!=item.data.id);

           this.setState({movies:newMovies, bestUserMovies:newBestUserMovies,sliderValue:5,sliding:false,showModal:false});
         }else{
           var item=this.state.shows[this.state.currentShowIndex];
           var genres = [];
           if (item.data.genres == undefined){
           }else{
             genres = item.data.genres;
           }
           var title = '';
           if (item.data.title == undefined){
           }else{
             title = item.data.title;
           }
           var tmdbRating = 0;
           if (item.data.tmdbRating == undefined){
   
           }else{
             tmdbRating = item.data.tmdbRating;
           }
           //UPDATE IN FIREBASE AND REMOVE FROM THIS MOVIES
           const addShowRef = collection(firestore, 'Users/'+this.state.thisUser.id+'/tv');
           await setDoc(doc(addShowRef, item.data.id.toString()), {
             id:item.data.id.toString(),
             image:item.data.image,
             rating:this.state.sliderValue,
             predicted:item.data.finalRating,
             lastModified:Date.parse(dateStr),
             genres:genres,
             title:title,
             deleted:false,
             tmdbRating:tmdbRating
           });
   
           this.nextClicked();
   
           let newBestUserShows = this.state.bestUserShows;
           newBestUserShows = newBestUserShows.filter(s=>s.data.id!=item.data.id);

           let newShows = this.state.shows;
           newShows = newShows.filter(s=>s!=item);
           this.setState({shows:newShows, bestUserShows:newBestUserShows, sliderValue:5,sliding:false,showModal:false})
         }
      }
    }
  }

  render(): React.ReactNode {
    if (!this.state.showBestUser){
      return (
        <div style={{overflow:'hidden'}}>
          <div style={{
            backgroundColor:'black',
            // position:'absolute',
            zIndex:-1,
            overflow:'visible',
            justifyContent:'center',
            objectFit:'fill', 
            height:'200%', 
            width:'200%',
            // bottom:'100%',
            objectPosition:'center',
            position:'fixed',
          }}>
          <Blur radius={(this.state.height>this.state.width)?'40px':'60px'} transition="1ms">
          <img
          src={
            (this.state.view=='movie')?((this.state.movies.length>0)?this.state.movies[this.state.currentMovieIndex].data.image:'https://image.tmdb.org/t/p/w500/pP27zlm9yeKrCeDZLFLP2HKELot.jpg'):((this.state.shows.length>0)?this.state.shows[this.state.currentShowIndex].data.image:'https://image.tmdb.org/t/p/w500/pP27zlm9yeKrCeDZLFLP2HKELot.jpg')
          }
          height={'100%'}
          style={{objectFit:'fll', objectPosition:'center'}}
          width={'100%'}
          />
          </Blur>
          </div>

          {/* CONFIRM POPUP */}
          {(this.state.showModal) && (
          <div>
          <div  style={{width:this.state.width, position:'absolute',height:this.state.height, opacity:0.5, zIndex:2, backgroundColor:'black'}}>
          </div>
 
          <div style={{
            backgroundColor:'#232323', 
            position:'absolute', 
            zIndex:3, 
            width:this.state.popupWidth, 
            height:this.state.popupHeight, 
            display:'flex',
            marginLeft:this.state.width/2-this.state.popupWidth/2, 
            marginTop:this.state.height/2-this.state.popupHeight/2, 
            flexDirection:'column',
            borderRadius:12}}>
              <div style={{width:this.state.popupWidth}}>
              <h1 style={{textAlign:'center', alignSelf:'center',fontSize:24, marginTop:15, color:'white'}}>{`Add to watched with rating ${this.state.sliderValue}?`}</h1>
              </div>
              
              <div style={{width:this.state.width/4, display:'flex'}}>
              <Button onClick={()=>this.setState({showModal:false, sliderValue:5,sliding:false,})} style={{bottom:10, left:20, fontSize:18, position:'absolute'}}>
                CANCEL
              </Button>
              <Button onClick={()=>this.movieClicked()} style={{bottom:10, right:20, fontSize:18, position:'absolute'}}>
                YES
              </Button>
              </div>

          </div>
        </div>
          )}

        {(this.state.loading) && (
        <div style={{width:this.state.width, position:'absolute',height:this.state.height, opacity:0.6, zIndex:2, backgroundColor:'black'}}>
        <ThreeCircles
            height={this.state.width/8}
            width={this.state.width/8}
            radius = "9"
            color = '#1167b1ff'
            ariaLabel = 'three-dots-loading'
            wrapperStyle={{marginTop:this.state.height/2-this.state.width/8/2, zIndex:3, marginLeft:this.state.width/2-this.state.width/8/2, position:'absolute'}}
          />
        </div>  
          )}

        <div style={{justifyContent:'center', overflow:'hidden',width:this.state.width,height:this.state.height, backgroundColor:'transparent'}}>
        <div style={{
            width:this.state.width, 
            position:'absolute',
            backgroundColor:'transparent', 
            height:this.state.height*0.05, 
            top:this.state.height*0.1-10, 
            overflow:'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf:'center',
            alignContent:'center',
            flexDirection:'row',
            display:'flex'
            }}>
              <label onClick={()=>this.setState({view:'movie'})} style={{marginRight:20, color:(this.state.view=='movie')?'white':'gray'}} className='text-left text-1xl font-bold menu-item'>Movies</label>
            <label onClick={()=>this.setState({view:'tv'})} style={{marginLeft:20, color:(this.state.view=='tv')?'white':'gray'}} className='text-left text-1xl font-bold menu-item'>Shows</label>


          </div>

          <div style={{
            width:this.state.width, 
            position:'absolute',
            backgroundColor:'transparent', 
            height:this.state.height*0.05, 
            top:this.state.height*0.05-10, 
            overflow:'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf:'center',
            alignContent:'center',
            flexDirection:'row',
            display:'flex'
            }}>
              <label onClick={()=>this.setState({showBestUser:!this.state.showBestUser})} style={{color:(this.state.showBestUser)?'white':'gray'}} className='text-left text-1xl font-bold menu-item'>Best Matched User</label>
          </div>
          {(this.state.movies.length>0 && this.state.view=='movie') && (
          <div style={{width:this.state.width, alignSelf:'center', overflow:'hidden',justifyContent:'center', display:'flex', marginTop:(this.state.height/2)-((this.state.height*0.55)/2)}}>
          <Button style={{alignSelf:'center', textAlign:'center', color:'white'}} onClick={()=>this.backClicked()}>
            <FontAwesomeIcon size={(this.state.height>this.state.width)?'2x':'3x'} icon={solid('angle-left')} />
          </Button>

          <Stack spacing={4} style={{alignSelf:'center', justifyContent:'center', overflow:'hidden'}}>
            <ImageList sx={{width:(this.state.height*0.55)/1.54, height:this.state.height*0.55, justifyContent:'center', alignSelf:'center',overflow:'hidden'}} cols={1}>
                {this.getItemsToRender().map(item => (
                  <div style={{width:(this.state.height*0.55)/1.54, height:this.state.height*0.55, overflow:'hidden'}}>
                  <div style={{width:((this.state.height*0.55)/1.54)*0.2,height:40,zIndex:1, backgroundColor:(item.data.isWildcard)?'#d4af37':getColor(item.data.finalRating/10), position:'absolute', borderTopLeftRadius:8, borderBottomRightRadius:8, justifyContent:'center', display:'flex', alignItems:'center'}}>
                    <h1 style={{alignSelf:'center', color:'white', fontSize:'25px', textAlign:'center', verticalAlign:'center', marginTop:(40/2)-(25/2)}}>{Math.round(item.data.finalRating * 10) / 10}</h1>

                  </div>

                    <ImageListItem key={item.data.image} sx={{width:(this.state.height*0.55)/1.54, height:this.state.height*0.55, borderTopRightRadius:8,borderTopLeftRadius:8, alignSelf:'center'}}>
                        <img src={item.data.image} alt={item.data.id.toString()} loading={"lazy"} style={{borderRadius:8}}/>
                        {(this.state.sliding) && (
                        <div style={{width:((this.state.height*0.55)/1.54),height:40,zIndex:1,backgroundColor:getColor(this.state.sliderValue/10), position:'absolute', justifyContent:'center', display:'flex', bottom:25-1,alignItems:'center'}}>
                          <h1 style={{alignSelf:'center', color:'white', fontSize:'25px', textAlign:'center',verticalAlign:'center', marginTop:(40/2)-25/2}}>{this.state.sliderValue + '/10'}</h1>
                        </div>
                        )}
                        {(this.state.sliding) && (
                          <div style={{width:((this.state.height*0.55)/1.54),height:25,zIndex:1,backgroundColor:getColor(this.state.sliderValue/10), position:'absolute',borderBottomLeftRadius:8, borderBottomRightRadius:8,justifyContent:'center', display:'flex', bottom:0,alignItems:'center'}}>
                          <h1 style={{fontSize:15, color:'white', textAlign:'center', marginTop:25/2-15/2}}>{this.state.ratingDescriptor}</h1>
                          </div>
                        )}
  
                    </ImageListItem>
                  </div>

                ))}
                
            </ImageList>
          </Stack>
          <Button style={{alignSelf:'center', textAlign:'center', color:'white'}} onClick={()=>this.nextClicked()}>
            <FontAwesomeIcon size={(this.state.height>this.state.width)?'2x':'3x'} icon={solid('angle-right')} />
          </Button>

        <div style={{overflow:'hidden',width:this.state.width,height:40,zIndex:1, marginTop:this.state.height*0.55-(40/2),backgroundColor:'transparent', position:'absolute', borderTopLeftRadius:20, borderBottomRightRadius:20, justifyContent:'center', display:'flex', alignItems:'center'}}>
                    <Slider 
                    value={this.state.sliderValue}
                    style={{alignSelf:'center', width:(this.state.height*0.55)/1.54}}
                    min={0.1} 
                    max={9.9}
                    step={0.1}
                    pushable={false}
                    trackStyle={{backgroundColor:'lightgray'}}
                    railStyle={{backgroundColor:'#444444'}}
                    handleStyle={{backgroundColor:'white'}}
                    onChange={(value:number)=>{
                      if (value >= 0 && value <= 0.2){
                        this.setState({ratingDescriptor:'Really?'})
                      }
                      if (value >= 0.3 && value <= 0.9){
                        this.setState({ratingDescriptor:'Unbearable'})
                      }
                      if (value >= 1 && value <= 1.9){
                        this.setState({ratingDescriptor:'Terrible'})
                      }
                      if (value >= 2 && value <= 2.9){
                        this.setState({ratingDescriptor:'Very Bad'})
                      }
                      if (value >= 3 && value <= 3.9){
                        this.setState({ratingDescriptor:'Bad'})
                      }
                      if (value >= 4 && value <= 4.9){
                        this.setState({ratingDescriptor:'Below Average'})
                      }
                      if (value >= 5 && value <= 5.9){
                        this.setState({ratingDescriptor:'Average'})
                      }
                      if (value >= 6 && value <= 6.9){
                        this.setState({ratingDescriptor:'Above Average'})
                      }
                      if (value >= 7 && value <= 7.9){
                        this.setState({ratingDescriptor:'Good'})
                      }
                      if (value >= 8 && value <= 8.9){
                        this.setState({ratingDescriptor:'Very Good'})
                      }
                      if (value >= 9 && value <= 9.7){
                        this.setState({ratingDescriptor:'Exceptional'})
                      }
                      if (value >= 9.8 && value <= 9.9){
                        this.setState({ratingDescriptor:'Really?'})
                      }
                      this.setState({sliderValue:value, sliding:true})
                    }}
                    onAfterChange={(value: number)=>{
                      if (localStorage.getItem('userData') == '112511920029842415746'){
                        alert('Sign In To Use This Feature');
                        this.setState({sliderValue:5,sliding:false,showModal:false})
                      }else{
                        this.setState({sliderValue:value, showModal:true});
                      }
                      }}/>
                  
          </div>
          </div>
          )}

          {(this.state.shows.length>0 && this.state.view=='tv') && (
          <div style={{width:this.state.width, alignSelf:'center', overflow:'hidden',justifyContent:'center', display:'flex', marginTop:(this.state.height/2)-((this.state.height*0.55)/2)}}>
          <Button style={{alignSelf:'center', textAlign:'center', color:'white'}} onClick={()=>this.backClicked()}>
            <FontAwesomeIcon size={(this.state.height>this.state.width)?'2x':'3x'} icon={solid('angle-left')} />
          </Button>

          <Stack spacing={4} style={{alignSelf:'center', justifyContent:'center', overflow:'hidden'}}>
            <ImageList sx={{width:(this.state.height*0.55)/1.54, height:this.state.height*0.55, justifyContent:'center', alignSelf:'center',overflow:'hidden'}} cols={1}>
                {this.getItemsToRender().map(item => (
                  <div style={{width:(this.state.height*0.55)/1.54, height:this.state.height*0.55, overflow:'hidden'}}>
                  <div style={{width:((this.state.height*0.55)/1.54)*0.2,height:40,zIndex:1, backgroundColor:(item.data.isWildcard)?'#d4af37':getColor(item.data.finalRating/10), position:'absolute', borderTopLeftRadius:8, borderBottomRightRadius:8, justifyContent:'center', display:'flex', alignItems:'center'}}>
                    <h1 style={{alignSelf:'center', color:'white', fontSize:'25px', textAlign:'center', verticalAlign:'center', marginTop:(40/2)-(25/2)}}>{Math.round(item.data.finalRating * 10) / 10}</h1>
                  </div>
                    <ImageListItem key={item.data.image} sx={{width:(this.state.height*0.55)/1.54, height:this.state.height*0.55, borderTopRightRadius:8,borderTopLeftRadius:8, alignSelf:'center'}}>
                        <img src={item.data.image} alt={item.data.id.toString()} loading={"lazy"} style={{borderRadius:8}}/>
                        {(this.state.sliding) && (
                        <div style={{width:((this.state.height*0.55)/1.54),height:40,zIndex:1,backgroundColor:getColor(this.state.sliderValue/10), position:'absolute', justifyContent:'center', display:'flex', bottom:25-1,alignItems:'center'}}>
                          <h1 style={{alignSelf:'center', color:'white', fontSize:'25px', textAlign:'center',verticalAlign:'center', marginTop:(40/2)-25/2}}>{this.state.sliderValue + '/10'}</h1>
                        </div>
                        )}
                        {(this.state.sliding) && (
                          <div style={{width:((this.state.height*0.55)/1.54),height:25,zIndex:1,backgroundColor:getColor(this.state.sliderValue/10), position:'absolute',borderBottomLeftRadius:8, borderBottomRightRadius:8,justifyContent:'center', display:'flex', bottom:0,alignItems:'center'}}>
                          <h1 style={{fontSize:15, color:'white', textAlign:'center', marginTop:25/2-15/2}}>{this.state.ratingDescriptor}</h1>
                          </div>
                        )}
  
                    </ImageListItem>
                  </div>

                ))}
                
            </ImageList>
          </Stack>
          <Button style={{alignSelf:'center', textAlign:'center', color:'white'}} onClick={()=>this.nextClicked()}>
            <FontAwesomeIcon size={(this.state.height>this.state.width)?'2x':'3x'} icon={solid('angle-right')} />
          </Button>

        <div style={{overflow:'hidden',width:this.state.width,height:40,zIndex:1, marginTop:this.state.height*0.55-(40/2),backgroundColor:'transparent', position:'absolute', borderTopLeftRadius:20, borderBottomRightRadius:20, justifyContent:'center', display:'flex', alignItems:'center'}}>
                    <Slider 
                    value={this.state.sliderValue}
                    style={{alignSelf:'center', width:(this.state.height*0.55)/1.54}}
                    min={0.1} 
                    max={9.9}
                    step={0.1}
                    pushable={false}
                    trackStyle={{backgroundColor:'lightgray'}}
                    railStyle={{backgroundColor:'#444444'}}
                    handleStyle={{backgroundColor:'white'}}
                    onChange={(value:number)=>{
                      if (value >= 0 && value <= 0.9){
                        this.setState({ratingDescriptor:'Unbearable'})
                      }
                      if (value >= 1 && value <= 1.9){
                        this.setState({ratingDescriptor:'Terrible'})
                      }
                      if (value >= 2 && value <= 2.9){
                        this.setState({ratingDescriptor:'Very Bad'})
                      }
                      if (value >= 3 && value <= 3.9){
                        this.setState({ratingDescriptor:'Bad'})
                      }
                      if (value >= 4 && value <= 4.9){
                        this.setState({ratingDescriptor:'Below Average'})
                      }
                      if (value >= 5 && value <= 5.9){
                        this.setState({ratingDescriptor:'Average'})
                      }
                      if (value >= 6 && value <= 6.9){
                        this.setState({ratingDescriptor:'Above Average'})
                      }
                      if (value >= 7 && value <= 7.9){
                        this.setState({ratingDescriptor:'Good'})
                      }
                      if (value >= 8 && value <= 8.9){
                        this.setState({ratingDescriptor:'Very Good'})
                      }
                      if (value >= 9 && value <= 9.9){
                        this.setState({ratingDescriptor:'Excellent'})
                      }
                      this.setState({sliderValue:value, sliding:true})
                    }}
                    onAfterChange={(value: number)=>{
                      if (localStorage.getItem('userData') == '112511920029842415746'){
                        alert('Sign In To Use This Feature');
                        this.setState({sliderValue:5,sliding:false,showModal:false})
                      }else{
                        this.setState({sliderValue:value, showModal:true});
                      }
                      }}/>
                  
          </div>
          </div>
          )}


          {(this.state.movies.length==0 && !this.state.loading && this.state.view=='movie') && (
            <div className='max-w-[240px] m-auto py-4'>
              <h1 className='text-center font-bold' style={{color:'white', textDecoration:'none',fontSize:this.state.height/22,marginTop:this.state.height/2-this.state.height/22/2-this.state.height*0.1}}>No Movies Rated</h1>
              <h1 className='text-center font-bold' style={{color:'white', textDecoration:'none',fontSize:this.state.height/28}}>Add some movies from the search page, rate it on the watched page, and then come back here</h1>
          </div>
          )}
          {(this.state.shows.length==0 && !this.state.loading && this.state.view=='tv') && (
            <div className='max-w-[240px] m-auto py-4'>
              <h1 className='text-center font-bold' style={{color:'white', textDecoration:'none',fontSize:this.state.height/22,marginTop:this.state.height/2-this.state.height/22/2-this.state.height*0.1}}>No Shows Rated</h1>
              <h1 className='text-center font-bold' style={{color:'white', textDecoration:'none',fontSize:this.state.height/28}}>Add some shows from the search page, rate it on the watched page, and then come back here</h1>
          </div>
          )}
        </div>

        </div>

      );
    }else{
      return (
        <div style={{overflow:'hidden'}}>
          <div style={{
            backgroundColor:'black',
            // position:'absolute',
            zIndex:-1,
            overflow:'visible',
            justifyContent:'center',
            objectFit:'fill', 
            height:'200%', 
            width:'200%',
            // bottom:'100%',
            objectPosition:'center',
            position:'fixed',
          }}>
          <Blur radius={(this.state.height>this.state.width)?'40px':'60px'} transition="1ms">
          <img
          src={
            (this.state.view=='movie')?((this.state.bestUserMovies.length>0)?this.state.bestUserMovies[this.state.currentBestUserMovieIndex].data.image:'https://image.tmdb.org/t/p/w500/pP27zlm9yeKrCeDZLFLP2HKELot.jpg'):((this.state.bestUserShows.length>0)?this.state.bestUserShows[this.state.currentBestUserShowIndex].data.image:'https://image.tmdb.org/t/p/w500/pP27zlm9yeKrCeDZLFLP2HKELot.jpg')
          }
          height={'100%'}
          style={{objectFit:'fll', objectPosition:'center'}}
          width={'100%'}
          />
          </Blur>
          </div>

          {/* CONFIRM POPUP */}
          {(this.state.showModal) && (
          <div>
          <div  style={{width:this.state.width, position:'absolute',height:this.state.height, opacity:0.5, zIndex:2, backgroundColor:'black'}}>
          </div>
 
          <div style={{
            backgroundColor:'#232323', 
            position:'absolute', 
            zIndex:3, 
            width:this.state.popupWidth, 
            height:this.state.popupHeight, 
            display:'flex',
            marginLeft:this.state.width/2-this.state.popupWidth/2, 
            marginTop:this.state.height/2-this.state.popupHeight/2, 
            flexDirection:'column',
            borderRadius:12}}>
              <div style={{width:this.state.popupWidth}}>
              <h1 style={{textAlign:'center', alignSelf:'center',fontSize:24, marginTop:15, color:'white'}}>{`Add to watched with rating ${this.state.sliderValue}?`}</h1>
              </div>
              
              <div style={{width:this.state.width/4, display:'flex'}}>
              <Button onClick={()=>this.setState({showModal:false, sliderValue:5,sliding:false,})} style={{bottom:10, left:20, fontSize:18, position:'absolute'}}>
                CANCEL
              </Button>
              <Button onClick={()=>this.movieClicked()} style={{bottom:10, right:20, fontSize:18, position:'absolute'}}>
                YES
              </Button>
              </div>

          </div>
        </div>
          )}

        {(this.state.loading) && (
        <div style={{width:this.state.width, position:'absolute',height:this.state.height, opacity:0.6, zIndex:2, backgroundColor:'black'}}>
        <ThreeCircles
            height={this.state.width/8}
            width={this.state.width/8}
            radius = "9"
            color = '#1167b1ff'
            ariaLabel = 'three-dots-loading'
            wrapperStyle={{marginTop:this.state.height/2-this.state.width/8/2, zIndex:3, marginLeft:this.state.width/2-this.state.width/8/2, position:'absolute'}}
          />
        </div>  
          )}

        <div style={{justifyContent:'center', overflow:'hidden',width:this.state.width,height:this.state.height, backgroundColor:'transparent'}}>
        <div style={{
            width:this.state.width, 
            position:'absolute',
            backgroundColor:'transparent', 
            height:this.state.height*0.05, 
            top:this.state.height*0.1-10, 
            overflow:'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf:'center',
            alignContent:'center',
            flexDirection:'row',
            display:'flex'
            }}>
              <label onClick={()=>this.setState({view:'movie'})} style={{marginRight:20, color:(this.state.view=='movie')?'white':'gray'}} className='text-left text-1xl font-bold menu-item'>Movies</label>
            <label onClick={()=>this.setState({view:'tv'})} style={{marginLeft:20, color:(this.state.view=='tv')?'white':'gray'}} className='text-left text-1xl font-bold menu-item'>Shows</label>


          </div>
          <div style={{
            width:this.state.width, 
            position:'absolute',
            backgroundColor:'transparent', 
            height:this.state.height*0.05, 
            top:this.state.height*0.05-10, 
            overflow:'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf:'center',
            alignContent:'center',
            flexDirection:'row',
            display:'flex'
            }}>
              <label onClick={()=>this.setState({showBestUser:!this.state.showBestUser})} style={{color:(this.state.showBestUser)?'white':'gray'}} className='text-left text-1xl font-bold menu-item'>Best Matched User</label>


          </div>
          {(this.state.bestUserMovies.length>0 && this.state.view=='movie') && (
          <div style={{width:this.state.width, alignSelf:'center', overflow:'hidden',justifyContent:'center', display:'flex', marginTop:(this.state.height/2)-((this.state.height*0.55)/2)}}>
          <Button style={{alignSelf:'center', textAlign:'center', color:'white'}} onClick={()=>this.backClicked()}>
            <FontAwesomeIcon size={(this.state.height>this.state.width)?'2x':'3x'} icon={solid('angle-left')} />
          </Button>

          <Stack spacing={4} style={{alignSelf:'center', justifyContent:'center', overflow:'hidden'}}>
            <ImageList sx={{width:(this.state.height*0.55)/1.54, height:this.state.height*0.55, justifyContent:'center', alignSelf:'center',overflow:'hidden'}} cols={1}>
                {this.getItemsToRender().map(item => (
                  <div style={{width:(this.state.height*0.55)/1.54, height:this.state.height*0.55, overflow:'hidden'}}>
                  <div style={{width:((this.state.height*0.55)/1.54)*0.2,height:40,zIndex:1, backgroundColor:getColor(item.data.rating/10), position:'absolute', borderTopLeftRadius:8, borderBottomRightRadius:8, justifyContent:'center', display:'flex', alignItems:'center'}}>
                    <h1 style={{alignSelf:'center', color:'white', fontSize:'25px', textAlign:'center', verticalAlign:'center', marginTop:(40/2)-(25/2)}}>{Math.round(item.data.rating * 10) / 10}</h1>
                  </div>

                  {(this.state.view =='movie') && (
                    <div style={{width:((this.state.height*0.55)/1.54)*0.25,height:40,zIndex:1, marginLeft:(this.state.height*0.55)/1.54-((this.state.height*0.55)/1.54)*0.25,backgroundColor:getColor(this.state.bestMovieMatch/100), position:'absolute', borderTopRightRadius:8, borderBottomLeftRadius:8, justifyContent:'center', display:'flex', alignItems:'center'}}>
                      <h1 style={{alignSelf:'center', color:'white', fontSize:'25px', textAlign:'center', verticalAlign:'center', marginTop:(40/2)-(25/2)}} className='text-left text-1xl menu-item'>{Math.round(this.state.bestMovieMatch) + '%'}</h1>
                    </div>
                  )}

                    <ImageListItem key={item.data.image} sx={{width:(this.state.height*0.55)/1.54, height:this.state.height*0.55, borderTopRightRadius:8,borderTopLeftRadius:8, alignSelf:'center'}}>
                        <img src={item.data.image} alt={item.data.id.toString()} loading={"lazy"} style={{borderRadius:8}}/>
                        {(this.state.sliding) && (
                        <div style={{width:((this.state.height*0.55)/1.54),height:40,zIndex:1,backgroundColor:getColor(this.state.sliderValue/10), position:'absolute', justifyContent:'center', display:'flex', bottom:25-1,alignItems:'center'}}>
                          <h1 style={{alignSelf:'center', color:'white', fontSize:'25px', textAlign:'center',verticalAlign:'center', marginTop:(40/2)-25/2}}>{this.state.sliderValue + '/10'}</h1>
                        </div>
                        )}
                        {(this.state.sliding) && (
                          <div style={{width:((this.state.height*0.55)/1.54),height:25,zIndex:1,backgroundColor:getColor(this.state.sliderValue/10), position:'absolute',borderBottomLeftRadius:8, borderBottomRightRadius:8,justifyContent:'center', display:'flex', bottom:0,alignItems:'center'}}>
                          <h1 style={{fontSize:15, color:'white', textAlign:'center', marginTop:25/2-15/2}}>{this.state.ratingDescriptor}</h1>
                          </div>
                        )}
  
                    </ImageListItem>
                  </div>

                ))}
                
            </ImageList>
          </Stack>
          <Button style={{alignSelf:'center', textAlign:'center', color:'white'}} onClick={()=>this.nextClicked()}>
            <FontAwesomeIcon size={(this.state.height>this.state.width)?'2x':'3x'} icon={solid('angle-right')} />
          </Button>

        <div style={{overflow:'hidden',width:this.state.width,height:40,zIndex:1, marginTop:this.state.height*0.55-(40/2),backgroundColor:'transparent', position:'absolute', borderTopLeftRadius:20, borderBottomRightRadius:20, justifyContent:'center', display:'flex', alignItems:'center'}}>
                    <Slider 
                    value={this.state.sliderValue}
                    style={{alignSelf:'center', width:(this.state.height*0.55)/1.54}}
                    min={0.1} 
                    max={9.9}
                    step={0.1}
                    pushable={false}
                    trackStyle={{backgroundColor:'lightgray'}}
                    railStyle={{backgroundColor:'#444444'}}
                    handleStyle={{backgroundColor:'white'}}
                    onChange={(value:number)=>{
                      if (value >= 0 && value <= 0.9){
                        this.setState({ratingDescriptor:'Unbearable'})
                      }
                      if (value >= 1 && value <= 1.9){
                        this.setState({ratingDescriptor:'Terrible'})
                      }
                      if (value >= 2 && value <= 2.9){
                        this.setState({ratingDescriptor:'Very Bad'})
                      }
                      if (value >= 3 && value <= 3.9){
                        this.setState({ratingDescriptor:'Bad'})
                      }
                      if (value >= 4 && value <= 4.9){
                        this.setState({ratingDescriptor:'Below Average'})
                      }
                      if (value >= 5 && value <= 5.9){
                        this.setState({ratingDescriptor:'Average'})
                      }
                      if (value >= 6 && value <= 6.9){
                        this.setState({ratingDescriptor:'Above Average'})
                      }
                      if (value >= 7 && value <= 7.9){
                        this.setState({ratingDescriptor:'Good'})
                      }
                      if (value >= 8 && value <= 8.9){
                        this.setState({ratingDescriptor:'Very Good'})
                      }
                      if (value >= 9 && value <= 9.9){
                        this.setState({ratingDescriptor:'Excellent'})
                      }
                      this.setState({sliderValue:value, sliding:true})
                    }}
                    onAfterChange={(value: number)=>{
                      if (localStorage.getItem('userData') == '112511920029842415746'){
                        alert('Sign In To Use This Feature');
                        this.setState({sliderValue:5,sliding:false,showModal:false})
                      }else{
                        this.setState({sliderValue:value, showModal:true});
                      }
                      }}/>
                  
          </div>
          </div>
          )}

          {(this.state.bestUserShows.length>0 && this.state.view=='tv') && (
          <div style={{width:this.state.width, alignSelf:'center', overflow:'hidden',justifyContent:'center', display:'flex', marginTop:(this.state.height/2)-((this.state.height*0.55)/2)}}>
          <Button style={{alignSelf:'center', textAlign:'center', color:'white'}} onClick={()=>this.backClicked()}>
            <FontAwesomeIcon size={(this.state.height>this.state.width)?'2x':'3x'} icon={solid('angle-left')} />
          </Button>

          <Stack spacing={4} style={{alignSelf:'center', justifyContent:'center', overflow:'hidden'}}>
            <ImageList sx={{width:(this.state.height*0.55)/1.54, height:this.state.height*0.55, justifyContent:'center', alignSelf:'center',overflow:'hidden'}} cols={1}>
                {this.getItemsToRender().map(item => (
                  <div style={{width:(this.state.height*0.55)/1.54, height:this.state.height*0.55, overflow:'hidden'}}>
                  <div style={{width:((this.state.height*0.55)/1.54)*0.2,height:40,zIndex:1, backgroundColor:getColor(item.data.rating/10), position:'absolute', borderTopLeftRadius:8, borderBottomRightRadius:8, justifyContent:'center', display:'flex', alignItems:'center'}}>
                    <h1 style={{alignSelf:'center', color:'white', fontSize:'25px', textAlign:'center', verticalAlign:'center', marginTop:(40/2)-(25/2)}}>{Math.round(item.data.rating * 10) / 10}</h1>
                  </div>

                  {(this.state.view =='tv') && (
                    <div style={{width:((this.state.height*0.55)/1.54)*0.25,height:40,zIndex:1, marginLeft:(this.state.height*0.55)/1.54-((this.state.height*0.55)/1.54)*0.25,backgroundColor:getColor(this.state.bestShowMatch/100), position:'absolute', borderTopRightRadius:8, borderBottomLeftRadius:8, justifyContent:'center', display:'flex', alignItems:'center'}}>
                      <h1 style={{alignSelf:'center', color:'white', fontSize:'25px', textAlign:'center', verticalAlign:'center', marginTop:(40/2)-(25/2)}} className='text-left text-1xl menu-item'>{Math.round(this.state.bestShowMatch) + '%'}</h1>
                    </div>
                  )}


                    <ImageListItem key={item.data.image} sx={{width:(this.state.height*0.55)/1.54, height:this.state.height*0.55, borderTopRightRadius:8,borderTopLeftRadius:8, alignSelf:'center'}}>
                        <img src={item.data.image} alt={item.data.id.toString()} loading={"lazy"} style={{borderRadius:8}}/>
                        {(this.state.sliding) && (
                        <div style={{width:((this.state.height*0.55)/1.54),height:40,zIndex:1,backgroundColor:getColor(this.state.sliderValue/10), position:'absolute', justifyContent:'center', display:'flex', bottom:25-1,alignItems:'center'}}>
                          <h1 style={{alignSelf:'center', color:'white', fontSize:'25px', textAlign:'center',verticalAlign:'center', marginTop:(40/2)-25/2}}>{this.state.sliderValue + '/10'}</h1>
                        </div>
                        )}
                        {(this.state.sliding) && (
                          <div style={{width:((this.state.height*0.55)/1.54),height:25,zIndex:1,backgroundColor:getColor(this.state.sliderValue/10), position:'absolute',borderBottomLeftRadius:8, borderBottomRightRadius:8,justifyContent:'center', display:'flex', bottom:0,alignItems:'center'}}>
                          <h1 style={{fontSize:15, color:'white', textAlign:'center', marginTop:25/2-15/2}}>{this.state.ratingDescriptor}</h1>
                          </div>
                        )}
  
                    </ImageListItem>
                  </div>

                ))}
                
            </ImageList>
          </Stack>
          <Button style={{alignSelf:'center', textAlign:'center', color:'white'}} onClick={()=>this.nextClicked()}>
            <FontAwesomeIcon size={(this.state.height>this.state.width)?'2x':'3x'} icon={solid('angle-right')} />
          </Button>

        <div style={{overflow:'hidden',width:this.state.width,height:40,zIndex:1, marginTop:this.state.height*0.55-(40/2),backgroundColor:'transparent', position:'absolute', borderTopLeftRadius:20, borderBottomRightRadius:20, justifyContent:'center', display:'flex', alignItems:'center'}}>
                    <Slider 
                    value={this.state.sliderValue}
                    style={{alignSelf:'center', width:(this.state.height*0.55)/1.54}}
                    min={0.1} 
                    max={9.9}
                    step={0.1}
                    pushable={false}
                    trackStyle={{backgroundColor:'lightgray'}}
                    railStyle={{backgroundColor:'#444444'}}
                    handleStyle={{backgroundColor:'white'}}
                    onChange={(value:number)=>{
                      if (value >= 0 && value <= 0.9){
                        this.setState({ratingDescriptor:'Unbearable'})
                      }
                      if (value >= 1 && value <= 1.9){
                        this.setState({ratingDescriptor:'Terrible'})
                      }
                      if (value >= 2 && value <= 2.9){
                        this.setState({ratingDescriptor:'Very Bad'})
                      }
                      if (value >= 3 && value <= 3.9){
                        this.setState({ratingDescriptor:'Bad'})
                      }
                      if (value >= 4 && value <= 4.9){
                        this.setState({ratingDescriptor:'Below Average'})
                      }
                      if (value >= 5 && value <= 5.9){
                        this.setState({ratingDescriptor:'Average'})
                      }
                      if (value >= 6 && value <= 6.9){
                        this.setState({ratingDescriptor:'Above Average'})
                      }
                      if (value >= 7 && value <= 7.9){
                        this.setState({ratingDescriptor:'Good'})
                      }
                      if (value >= 8 && value <= 8.9){
                        this.setState({ratingDescriptor:'Very Good'})
                      }
                      if (value >= 9 && value <= 9.9){
                        this.setState({ratingDescriptor:'Excellent'})
                      }
                      this.setState({sliderValue:value, sliding:true})
                    }}
                    onAfterChange={(value: number)=>{
                      if (localStorage.getItem('userData') == '112511920029842415746'){
                        alert('Sign In To Use This Feature');
                        this.setState({sliderValue:5,sliding:false,showModal:false})
                      }else{
                        this.setState({sliderValue:value, showModal:true});
                      }
                      }}/>
                  
          </div>
          </div>
          )}


          {(this.state.bestUserMovies.length==0 && !this.state.loading && this.state.view=='movie') && (
            <div className='max-w-[240px] m-auto py-4'>
              <h1 className='text-center font-bold' style={{color:'white', textDecoration:'none',fontSize:this.state.height/22,marginTop:this.state.height/2-this.state.height/22/2-this.state.height*0.1}}>No Movies Rated</h1>
              <h1 className='text-center font-bold' style={{color:'white', textDecoration:'none',fontSize:this.state.height/28}}>Add some movies from the search page, rate it on the watched page, and then come back here</h1>
          </div>
          )}
          {(this.state.bestUserShows.length==0 && !this.state.loading && this.state.view=='tv') && (
            <div className='max-w-[240px] m-auto py-4'>
              <h1 className='text-center font-bold' style={{color:'white', textDecoration:'none',fontSize:this.state.height/22,marginTop:this.state.height/2-this.state.height/22/2-this.state.height*0.1}}>No Shows Rated</h1>
              <h1 className='text-center font-bold' style={{color:'white', textDecoration:'none',fontSize:this.state.height/28}}>Add some shows from the search page, rate it on the watched page, and then come back here</h1>
          </div>
          )}
        </div>

        </div>

      );
    }

    }

};

export default Main;
