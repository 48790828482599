import React, { useEffect } from 'react';
// import { GoogleButton } from 'react-google-button';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { collection, doc, setDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import Cookies from 'js-cookie';

import logo from '../.assets/logo.png'
import { ThreeCircles } from 'react-loader-spinner';
import Blur from 'react-css-blur';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import GoogleButton from 'react-google-button'



const Signin = () => {
  const { googleSignIn, user } = UserAuth();
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
      console.log('success')

    } catch (error) {
      console.erro(error);
    }
  };

  useEffect(() => {
    if (user != null) {

      if (JSON.stringify(user)!=='{}'){
        navigate('/account');
        console.log('success')

      }else{
        console.log('not logged in: ' + JSON.stringify(user))

      }
    }else{
      navigate('/signin')
    }
  }, [user]);

  
  if (user==null){
    return (
      <div>
        <div style={{
            backgroundColor:'black',
            // position:'absolute',
            zIndex:-1,
            overflow:'visible',
            justifyContent:'center',
            objectFit:'fill', 
            height:'200%', 
            width:'200%',
            // bottom:'100%',
            objectPosition:'center',
            position:'fixed',
          }}>
          <Blur radius={'60px'} transition="1ms">
          <img
          src={'https://image.tmdb.org/t/p/w500/pP27zlm9yeKrCeDZLFLP2HKELot.jpg'
          }
          height={'100%'}
          style={{objectFit:'fll', objectPosition:'center'}}
          width={'100%'}
          />

          </Blur>
        </div>  

        <div style={{width:window.innerWidth, height:window.innerHeight,justifyContent:'center', display:'flex', overflowX:'hidden'}}>
          <div style={{flexDirection:'column', display:'flex', overflowX:'hidden', marginBottom:window.innerHeight*0.1}}>
          <h1 style={{color:'white', fontSize:28, marginTop:window.innerHeight*0.1, alignSelf:'center'}}>Welcome To The Movie Way</h1>
          {(window.innerHeight>window.innerWidth) && (
            <img src={logo} style={{width:window.innerWidth/3, height:window.innerWidth/3, borderRadius:12, alignSelf:'center', marginTop:10}}></img>
          )}
          {(window.innerHeight<=window.innerWidth) && (
            <img src={logo} style={{width:window.innerWidth/8, height:window.innerWidth/8, borderRadius:12, alignSelf:'center', marginTop:20}}></img>
          )}
          <h1 style={{color:'white', fontSize:22, marginTop:20, alignSelf:'center', fontWeight:'bold'}}>Getting Started</h1>
          <h1 style={{color:'white', width:'100%', textAlign:'center', fontSize:18, paddingLeft:10,paddingRight:10, textAnchor:'middle', textSizeAdjust:'auto'}}>The Movie Way is not your average recommendation website. Our system recommends movies and shows based solely on other user's rating and your similarity to them. The more movies and shows you rate the more accurate your recommendations will be and rating honestly is important.</h1>
          <h1 style={{color:'white', width:'100%', textAlign:'center', fontSize:18, padding:10, textAnchor:'middle', textSizeAdjust:'auto'}}>In order to recieve personalised recommendations you must be signed in. However, you can use the currently logged in test account to try it out.</h1>

          <GoogleButton
            type='dark'
            style={{alignSelf:'center', marginTop:20, position:'absolute', bottom:30}}
            onClick={handleGoogleSignIn}
          />
          </div>
        </div>
        
        {/* <div style={{justifyContent:'center',flexDirection:'column', width:window.innerWidth, height:window.innerHeight, display:'flex', overflow:'hidden'}}>
        {(window.innerHeight>window.innerWidth) && (
        <div style={{
          overflow:'hidden',
          width:window.innerWidth,
          height:window.innerHeight, 
          display:'flex', 
          borderRadius:12, 
          marginLeft:window.innerWidth/2-window.innerWidth/3/2,
          }}>

          <img src={logo} style={{width:window.innerWidth/3, height:window.innerWidth/3, borderRadius:12, alignSelf:'center'}}></img>
        </div>
        )}
       {(window.innerHeight<=window.innerWidth) && (
        <div style={{
          overflow:'hidden',
          width:window.innerWidth,
          height:window.innerHeight, 
          display:'flex', 
          borderRadius:12, 
          marginLeft:window.innerWidth/2-window.innerWidth/8/2,
          }}>
          <img src={logo} style={{width:window.innerWidth/8, height:window.innerWidth/8, borderRadius:12, alignSelf:'center'}}></img>
        </div>
        )}
        <div style={{position:'absolute', alignSelf:'center',marginTop:(window.innerHeight/3)}}>

          <GoogleButton
          type='dark'
          onClick={handleGoogleSignIn}
        />
        </div>
        </div> */}

      </div>

  );
  }else{
    return (
      <div>
        <div style={{
            backgroundColor:'black',
            // position:'absolute',
            zIndex:-1,
            overflow:'visible',
            justifyContent:'center',
            objectFit:'fill', 
            height:'200%', 
            width:'200%',
            // bottom:'100%',
            objectPosition:'center',
            position:'fixed',
          }}>
          <Blur radius={'60px'} transition="1ms">
          <img
          src={'https://image.tmdb.org/t/p/w500/pP27zlm9yeKrCeDZLFLP2HKELot.jpg'
          }
          height={'100%'}
          style={{objectFit:'fll', objectPosition:'center'}}
          width={'100%'}
          />

          </Blur>
        </div>  

          <div style={{justifyContent:'center', overflow:'hidden', height:window.innerHeight, display:'flex'}}>
 
          {/* <h1 style={{alignSelf:'center', color:'#1167b1'}}>LOADING</h1> */}
          <ThreeCircles
            height={window.innerWidth/8}
            width={window.innerWidth/8}
            radius = "9"
            color = '#1167b1'
            ariaLabel = 'three-dots-loading'     
            wrapperStyle={{marginTop:window.innerHeight/2-window.innerWidth/8/2}}
          />
          </div>
      </div>

    );
  }
};

export default Signin;
