import { addDoc, collection, collectionGroup, doc, getDocs, limit, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import React from 'react';
import {firestore} from '../firebase'
import { UserAuth } from '../context/AuthContext';
import Cookies from 'js-cookie';
import similarity from 'compute-cosine-similarity'
import { Stack, ImageList, ImageListItem, Button, TextField } from '@mui/material'
import Blur from 'react-css-blur'
import noimageImage from '../.assets/noimage.gif'

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { ThreeCircles } from 'react-loader-spinner';
import ReactToolTip from 'react-tooltip'
const axios = require('axios').default;

type State = {
  loading?:string;
  thisUser?:any;
  searchResultMovies?:object;
  searchResultShows?:object;
  currentPageIndex?:number;
  searchQuery?:string;
  width?:number;
  height:number;
  view?:string;
}


export function getColor(pct) {
  var percentColors = [
    { pct: 0.0, color: { r: 0xde, g: 0x37, b: 0x00 } },
    { pct: 0.5, color: { r: 0xff, g: 0x83, b: 0x03 } },
    { pct: 1.0, color: { r: 0x2a, g: 0xa1, b: 0x0f } } ];

    for (var i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
          break;
      }
    }
    var lower = percentColors[i - 1];
    var upper = percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return rgbToHex(color.r, color.g, color.b)
    //return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
    // or output as hex if preferred
}
function rgbToHex(red, green, blue) {
  const rgb = (red << 16) | (green << 8) | (blue << 0);
  return '#' + (0x1000000 + rgb).toString(16).slice(1);
}

class Search extends React.Component<{}, State> {
  constructor(props){
    super(props);

    this.state={
      loading:'Hello',
      thisUser:{},
      searchResultMovies:[],
      searchResultShows:[],
      currentPageIndex:1,
      searchQuery:'',
      width:window.innerWidth,
      height:window.innerHeight,
      numberOfColumns:(window.innerHeight>window.innerWidth)?2:5,
      view:'movie'
    }
  }

  async componentDidMount(): void {
    window.addEventListener('resize', this.updateDimensions);

    const userId = localStorage.getItem('userData');

    if (userId == 'undefined' || userId == '112511920029842415746' || userId == null || userId == undefined){
      localStorage.setItem('userData', '112511920029842415746');
      await this.getThisUser();
      await this.handleSearch('movie','');
    }else{
      await this.getThisUser();
      await this.handleSearch('movie','');
    }

    this.setState({loading:false})

  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight});
    this.setState({numberOfColumns:(window.innerHeight>window.innerWidth)?2:5})
    // alert('window updated')
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  async getThisUser(){
    //GET THIS USER'S FIREBASE DOCUMENT
    //const userId = Cookies.get('userData');
    const userId = localStorage.getItem('userData');
    const thisUserRef = collection(firestore, "Users");
    const thisUserQuery = query(thisUserRef, where('id', '==',userId));
    const thisUserSnapshot = await getDocs(thisUserQuery);
    const thisUser = thisUserSnapshot.docs[0].data();

    this.setState({thisUser:thisUser})
  }

  deepClone(obj, hash = new WeakMap()) {
    // Do not try to clone primitives or functions
    if (Object(obj) !== obj || obj instanceof Function) return obj;
    if (hash.has(obj)) return hash.get(obj); // Cyclic reference
    try { // Try to run constructor (without arguments, as we don't know them)
        var result = new obj.constructor();
    } catch(e) { // Constructor failed, create object without running the constructor
        result = Object.create(Object.getPrototypeOf(obj));
    }
    // Optional: support for some standard constructors (extend as desired)
    if (obj instanceof Map)
        Array.from(obj, ([key, val]) => result.set(this.deepClone(key, hash), 
                                                   this.deepClone(val, hash)) );
    else if (obj instanceof Set)
        Array.from(obj, (key) => result.add(this.deepClone(key, hash)) );
    // Register in hash    
    hash.set(obj, result);
    // Clone and assign enumerable own properties recursively
    return Object.assign(result, ...Object.keys(obj).map (
        key => ({ [key]: this.deepClone(obj[key], hash) }) ));
  }


  async handleSearch(view=this.state.view,text=this.state.searchQuery.trim()){
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;

    if (text.length>0){
      if (view=='movie'){
        try{
          await axios.get(`https://api.themoviedb.org/3/search/movie?api_key=56746fb604baa81be557f341f2ec2f83&language=en_UK&page=1&include_adult=false&query=${text}&with_original_language=en`)
          .then(async (response) => {
            const movies = response.data.results.slice(0,10);
    
            this.setState({ searchResultMovies: movies});
          });
        }catch (error){
          console.log(error)
        }
      }else{
        try{
          await axios.get(`https://api.themoviedb.org/3/search/tv?api_key=56746fb604baa81be557f341f2ec2f83&language=en_UK&page=1&include_adult=false&query=${text.trim()}&with_original_language=en`)
          .then(async (response) => {
            const shows = response.data.results.slice(0,10);
    
            this.setState({ searchResultShows: shows});
          });
        }catch (error){
          console.log(error)
        }
      }

    }else{
      if (view=='movie'){
        try{
          await axios.get(`https://api.themoviedb.org/3/discover/movie?api_key=56746fb604baa81be557f341f2ec2f83&language=en_UK&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&release_date.gte=1980-01-01&release_date.lte=${today}&with_watch_monetization_types=flatrate&with_original_language=en`)
          .then(async (response) => {
            const movies = response.data.results;
    
            this.setState({ searchResultMovies: movies});
          });
        }catch (error){
          console.log(error)
        }
      }else{
        try{
          await axios.get(`https://api.themoviedb.org/3/discover/tv?api_key=56746fb604baa81be557f341f2ec2f83&language=en_UK&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&release_date.gte=1980-01-01&release_date.lte=${today}&with_watch_monetization_types=flatrate&with_original_language=en`)
          .then(async (response) => {
            const shows = response.data.results;
    
            this.setState({ searchResultShows: shows});
          });
        }catch (error){
          console.log(error)
        }
      }
    }

  }

  async movieClicked(item){
    if (localStorage.getItem('userData') == '112511920029842415746'){
      alert('Sign In To Use This Feature')
    }else{
      const itemId = (item.id).toString();
      var date = new Date();
      var dateStr =
      ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
      ("00" + date.getDate()).slice(-2) + "/" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2) + ":" +
      ("00" + date.getSeconds()).slice(-2);

      if (this.state.view=='movie'){
          
        const allMoviesRef = collection(firestore, 'Users/' + this.state.thisUser.id +'/movie');

        const allMoviesQuery = query(allMoviesRef, where('id','==',itemId), where('deleted','==',false));

        const allMoviesSnapshot = await getDocs(allMoviesQuery);

        if (allMoviesSnapshot.docs.length == 0){
          var answer = window.confirm(`Add '${item.original_title}' To Watched?`);
          if (answer) {
            //UPDATE IN FIREBASE AND REMOVE FROM THIS MOVIES
            const addMovieRef = collection(firestore, 'Users/'+this.state.thisUser.id+'/movie');
            await setDoc(doc(addMovieRef, itemId), {
              id:itemId,
              image:"https://image.tmdb.org/t/p/w500"+item.poster_path,
              title:item.original_title,
              genres:item.genre_ids,
              rating:'0',
              deleted:false,
              lastModified:Date.parse(dateStr),
              tmdbRating:item.vote_average
            });
          }
        }else{
          alert('Already Added')
        }
      }else{
        const allShowsRef = collection(firestore, 'Users/' + this.state.thisUser.id +'/tv');

        const allShowsQuery = query(allShowsRef, where('id','==',itemId), where('deleted','==',false));

        const allShowsSnapshot = await getDocs(allShowsQuery);
        if (allShowsSnapshot.docs.length == 0){
          var answer = window.confirm(`Add '${item.name}' To Watched?`);
          if (answer) {
            //UPDATE IN FIREBASE AND REMOVE FROM THIS MOVIES
            const addShowRef = collection(firestore, 'Users/'+this.state.thisUser.id+'/tv');
            await setDoc(doc(addShowRef, itemId), {
              id:itemId,
              image:"https://image.tmdb.org/t/p/w500"+item.poster_path,
              title:item.name,
              genres:item.genre_ids,
              rating:'0',
              deleted:false,
              lastModified:Date.parse(dateStr),
              tmdbRating:item.vote_average
            });
          }
        }else{
          alert('Already Added')
        }
      }
    }
  }

  getItemsToRender(){
    if (this.state.view=='movie'){
      return this.state.searchResultMovies;
    }else if (this.state.view=='tv'){
      return this.state.searchResultShows;
    }
  }

  render(): React.ReactNode {
      return (
        <div style={{overflow:'hidden'}}>
          <div style={{
            backgroundColor:'black',
            zIndex:-1,
            overflow:'hidden',
            justifyContent:'center',
            objectFit:'fill', 
            height:'200%', 
            width:'200%',
            objectPosition:'center',
            position:'fixed',
          }}>
          <Blur radius={(this.state.height>this.state.width)?'40px':'60px'} transition="1ms">
          <img
          src={'https://image.tmdb.org/t/p/w500/pP27zlm9yeKrCeDZLFLP2HKELot.jpg'}
          height={'100%'}
          style={{objectFit:'fll', objectPosition:'center'}}
          width={'100%'}
          />
          </Blur>
          </div>
          {/* <div style={{width:50,height:50, position:'absolute', top:10, right:40}}>
          <p style={{color:'lightgray', textAlign:'left', alignSelf:'flex-start'}} data-tip="Disclaimer: All Movies and Shows are provided by TheMovieDB">Disclaimer</p>
          <ReactToolTip insecure={true}/>
          </div> */}


          {/* <h1 style={{top:10, right:10, color:'lightgray', position:'absolute', fontSize:16}}>Disclaimer: All Movies and Shows are provided by TheMovieDB</h1> */}

          {(this.state.loading) && (
        <div style={{width:this.state.width, position:'absolute',height:this.state.height, opacity:0.6, zIndex:2, backgroundColor:'black'}}>
        <ThreeCircles
            height={this.state.width/8}
            width={this.state.width/8}
            radius = "9"
            color = '#1167b1ff'
            ariaLabel = 'three-dots-loading'
            wrapperStyle={{marginTop:this.state.height/2-this.state.width/8/2, zIndex:3, marginLeft:this.state.width/2-this.state.width/8/2, position:'absolute'}}
          />
        </div>  
          )}
        
        <div style={{marginTop:70, position:'fixed'}}>
        <div 
          style={{
            justifyContent:'center',
            width:this.state.width,
            height:40, 
            color:'white',
            overflow:'hidden',
            backgroundColor:'transparent', 
            
          }}
        >
            {/* view navigation */}
            <div style={{
            width:this.state.width, 
            position:'absolute',
            backgroundColor:'transparent', 
            height:this.state.height*0.05, 
            top:0, 
            overflow:'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf:'center',
            alignContent:'center',
            flexDirection:'row',
            display:'flex'
            }}>
              <label onClick={()=>{this.setState({view:'movie'});this.handleSearch('movie','')}} style={{marginRight:20, color:(this.state.view=='movie')?'white':'gray'}} className='text-left text-1xl font-bold menu-item'>Movies</label>
            <label onClick={()=>{this.setState({view:'tv'});this.handleSearch('tv','')}} style={{marginLeft:20, color:(this.state.view=='tv')?'white':'gray'}} className='text-left text-1xl font-bold menu-item'>Shows</label>
          </div>
          
          <TextField
        placeholder='Search...'
        variant="standard"
        fullWidth
        sx={{ input: { color: 'white', fontSize:20 }}}
        InputProps={{
          disableUnderline: true,
        }}
        onChange={(event)=>{this.setState({searchQuery:event.target.value});this.handleSearch(this.state.view,event.target.value)}}
        style={{paddingLeft:this.state.width*0.02, marginTop:this.state.height*0.05, position:'absolute', zIndex:5}}
        />
        </div>


        <div style={{
          justifyContent:'center', 
          width:this.state.width,
          height:this.state.height*0.85,
          backgroundColor:'transparent',
          overflow:'visible',
          }}>
          
          <div style={{width:this.state.width,height:'100%',justifyContent:'center', marginTop:this.state.height*0.05}}>

          <Stack spacing={4} style={{alignSelf:'center', justifyContent:'center',display:'flex'}}>
            <ImageList sx={{width:this.state.width*0.98, height:this.state.height*0.85-this.state.height*0.05,justifyContent:'center', alignSelf:'center', overflowX:'hidden'}} cols={this.state.numberOfColumns}>
                {(this.getItemsToRender()).map(item => (
                  <div style={{cursor:'pointer'}} onClick={()=>this.movieClicked(item)}>
                    <ImageListItem key={item.id} sx={{width:(this.state.width*0.95)/this.state.numberOfColumns+0.15, height:((this.state.width*0.95)/this.state.numberOfColumns+0.15)*1.54, borderTopRightRadius:8,borderTopLeftRadius:8, alignSelf:'center'}}>

                    <img src={(item.poster_path==null)?"https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=20&m=1216251206&s=170667a&w=0&h=A72dFkHkDdSfmT6iWl6eMN9t_JZmqGeMoAycP-LMAw4=":"https://image.tmdb.org/t/p/w500"+item.poster_path} alt={item.id} loading={"lazy"} style={{borderRadius:8}}/>
                        
                   </ImageListItem>
                </div>

                ))}
                
            </ImageList>
        </Stack>
        
          </div>
        </div>
        </div>
        </div>

      );
  }

};

export default Search;
