import { addDoc, collection, collectionGroup, doc, getDocs, limit, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import React from 'react';
import {firestore} from '../firebase'
import { UserAuth } from '../context/AuthContext';
import Cookies from 'js-cookie';
import similarity from 'compute-cosine-similarity'
import { Stack, ImageList, ImageListItem, Button, colors } from '@mui/material'
import Blur from 'react-css-blur'
import { Audio, BallTriangle, Bars, Circles, CirclesWithBar, Grid, Hearts, Oval, Puff, TailSpin, ThreeCircles, ThreeDots } from  'react-loader-spinner'
import Modal from 'react-modal';
import Popup from 'reactjs-popup';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import nextIcon from '../.assets/next.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import axios from 'axios';


type State = {
  loading?:string;
  thisUser?:any;

  width?:number;
  height?:number;

  allMovies?:object;
  movieRatingRange?:number;
  showRatingRange?:number;

  allShows?:object;

  userCount?:number;

  view?:string;
}

export function getColor(pct) {
  var percentColors = [
    { pct: 0.0, color: { r: 0xde, g: 0x37, b: 0x00 } },
    { pct: 0.5, color: { r: 0xff, g: 0x83, b: 0x03 } },
    { pct: 1.0, color: { r: 0x2a, g: 0xa1, b: 0x0f } } ];

    for (var i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
          break;
      }
    }
    var lower = percentColors[i - 1];
    var upper = percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return rgbToHex(color.r, color.g, color.b)
    //return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
    // or output as hex if preferred
}
function rgbToHex(red, green, blue) {
  const rgb = (red << 16) | (green << 8) | (blue << 0);
  return '#' + (0x1000000 + rgb).toString(16).slice(1);
}

class Developer extends React.Component<{}, State> {
  constructor(props){
    super(props);

    this.state={
      loading:true,
      thisUser:{},

      width:window.innerWidth,
      height:window.innerHeight,

      allMovies:[],
      movieRatingRange:0,

      allShows:[],
      showRatingRange:0,
      userCount:0,

      view:'tv'
    }
  }

  async componentDidMount(): void {
    window.addEventListener('resize', this.updateDimensions);

    await this.getThisUser();
    await this.getTotalUsers();
    await this.getAllShows();

    await this.getAllMovies();

    this.setState({loading:false})
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight});
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }


  async getThisUser(){
    //GET THIS USER'S FIREBASE DOCUMENT
    const userId = localStorage.getItem('userData');
    const thisUserRef = collection(firestore, "Users");
    const thisUserQuery = query(thisUserRef, where('id', '==',userId));
    const thisUserSnapshot = await getDocs(thisUserQuery);
    const thisUser = thisUserSnapshot.docs[0].data();

    this.setState({thisUser:thisUser})
  }

  async getTotalUsers(){
    const usersRef = collection(firestore, "Users");
    const usersQuery = query(usersRef);
    const usersSnapshot = (await getDocs(usersQuery)).size;
    const usersSize = usersSnapshot;

    this.setState({userCount:usersSize});
  }

  deepClone(obj, hash = new WeakMap()) {
    // Do not try to clone primitives or functions
    if (Object(obj) !== obj || obj instanceof Function) return obj;
    if (hash.has(obj)) return hash.get(obj); // Cyclic reference
    try { // Try to run constructor (without arguments, as we don't know them)
        var result = new obj.constructor();
    } catch(e) { // Constructor failed, create object without running the constructor
        result = Object.create(Object.getPrototypeOf(obj));
    }
    // Optional: support for some standard constructors (extend as desired)
    if (obj instanceof Map)
        Array.from(obj, ([key, val]) => result.set(this.deepClone(key, hash), 
                                                   this.deepClone(val, hash)) );
    else if (obj instanceof Set)
        Array.from(obj, (key) => result.add(this.deepClone(key, hash)) );
    // Register in hash    
    hash.set(obj, result);
    // Clone and assign enumerable own properties recursively
    return Object.assign(result, ...Object.keys(obj).map (
        key => ({ [key]: this.deepClone(obj[key], hash) }) ));
  }

  async addMovie(){
      var date = new Date();
      var dateStr =
      ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
      ("00" + date.getDate()).slice(-2) + "/" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2) + ":" +
      ("00" + date.getSeconds()).slice(-2);

      var today = new Date();

      if (this.state.view=='movie'){
        try{
          await axios.get(`https://api.themoviedb.org/3/discover/movie?api_key=56746fb604baa81be557f341f2ec2f83&language=en_UK&sort_by=vote_average.desc&page=7&vote_count.gte=500&with_original_language=en`)
          .then(async (response) => {
            const movies = response.data.results;

            for (const item of movies){
              //UPDATE IN FIREBASE AND REMOVE FROM THIS MOVIES
              const addMovieRef = collection(firestore, 'Users/'+this.state.thisUser.id.toString()+'/movie');

              await setDoc(doc(addMovieRef, item.id.toString()), {
                id:item.id.toString(),
                image:'https://image.tmdb.org/t/p/w500'+item.poster_path,
                rating:item.vote_average,
                lastModified:Date.parse(dateStr),
                genres:item.genre_ids,
                title:item.original_title,
                deleted:false,
              });
            }
            console.log('DONE')
          });
        }catch (error){
          console.log(error)
        }
      }else{
        try{
          await axios.get(`https://api.themoviedb.org/3/discover/tv?api_key=56746fb604baa81be557f341f2ec2f83&language=en_UK&sort_by=vote_average.desc&sort_by=popularity.desc&page=1&vote_count.gte=500&with_original_language=en`)
          .then(async (response) => {
            const shows = response.data.results;

            for (const item of shows){
              //UPDATE IN FIREBASE AND REMOVE FROM THIS MOVIES
              const addShowRef = collection(firestore, 'Users/'+this.state.thisUser.id.toString()+'/tv');
              console.log(item)
              await setDoc(doc(addShowRef, item.id.toString()),{
                id:item.id.toString(),
                image:'https://image.tmdb.org/t/p/w500'+item.poster_path,
                rating:item.vote_average,
                lastModified:Date.parse(dateStr),
                genres:item.genre_ids,
                title:item.original_name,
                deleted:false,
              });
            }
            console.log('DONE')
    
          });
        }catch (error){
          console.log(error)
        }
      }
      

  }

  async getAllMovies(){
    try{
      //GET THIS USER'S FIREBASE DOCUMENT
      const userId = localStorage.getItem('userData');
      const thisUserRef = collection(firestore, "Users");
      const thisUserQuery = query(thisUserRef, where('id', '==',userId));
      const thisUserSnapshot = await getDocs(thisUserQuery);
      const thisUser = thisUserSnapshot.docs[0].data();

      this.setState({thisUser:thisUser})

      var lastMoviesRead = '';

      var allMovies = []

      //GET ALL MOVIES THAT HAVE CHANGGED SINCE THIS USERS'S LAST READ FROM FIREBASE
      if (Array.isArray(thisUser.readMovies)){
        for (const chunk of thisUser.readMovies){
          allMovies = allMovies.concat(JSON.parse(chunk));
        }
      }else{
        allMovies = JSON.parse(thisUser.readMovies)
      }

      allMovies = allMovies.filter(o=>o.data.deleted==false && parseFloat(o.data.rating) > 0);

      const movieGroupedData = allMovies.reduce((hash, obj) => ({...hash, [obj['userId']]:( hash[obj['userId']] || [] ).concat(obj)}), {})

      var sumOfMovieRatingRanges = 0;
  
      for (let userIndex in movieGroupedData) {
        let movies = movieGroupedData[userIndex];
  
        movies.sort(function(a, b) {
          return parseFloat(a.data.rating) - parseFloat(b.data.rating);
        }).reverse();
      
        const movieRatingRange = Math.abs(movies[0].data.rating - movies[movies.length-1].data.rating);
  
        sumOfMovieRatingRanges+=movieRatingRange;
      }
  
      this.setState({movieRatingRange:sumOfMovieRatingRanges/Object.entries(movieGroupedData).length});
      this.setState({allMovies:allMovies})
    }catch (e){
      console.log(e)
    }
  }

  async getAllShows(){
    try{
      //GET THIS USER'S FIREBASE DOCUMENT
      const userId = localStorage.getItem('userData');
      const thisUserRef = collection(firestore, "Users");
      const thisUserQuery = query(thisUserRef, where('id', '==',userId));
      const thisUserSnapshot = await getDocs(thisUserQuery);
      const thisUser = thisUserSnapshot.docs[0].data();

      this.setState({thisUser:thisUser})

      var lastShowsRead = '';

      var allShows = []

      //GET ALL MOVIES THAT HAVE CHANGGED SINCE THIS USERS'S LAST READ FROM FIREBASE
      if (Array.isArray(thisUser.readShows)){
        for (const chunk of thisUser.readShows){
          allShows = allShows.concat(JSON.parse(chunk));
        }
      }else{
        allShows = JSON.parse(thisUser.readShows)
      }

      allShows = allShows.filter(o=>o.data.deleted==false && parseFloat(o.data.rating) > 0);

      const showGroupedData = allShows.reduce((hash, obj) => ({...hash, [obj['userId']]:( hash[obj['userId']] || [] ).concat(obj)}), {})

      var sumOfShowRatingRanges = 0;
  
      for (let userIndex in showGroupedData) {
        let shows = showGroupedData[userIndex];
        
        shows.sort(function(a, b) {
          return parseFloat(a.data.rating) - parseFloat(b.data.rating);
        }).reverse();
      
        const showRatingRange = Math.abs(shows[0].data.rating - shows[shows.length-1].data.rating);
  
        sumOfShowRatingRanges+=showRatingRange;

      }
      this.setState({showRatingRange:sumOfShowRatingRanges/Object.entries(showGroupedData).length})

      this.setState({allShows:allShows})
    }catch (e){
      console.log(e)
    }
  }


  render(): React.ReactNode {
    if (localStorage.getItem('userData') == '102339298089133840790' || localStorage.getItem('userData') == '116430438492552652373'){
      return (
        <div style={{overflow:'hidden', backgroundColor:'transparent'}}>
          <div style={{
            backgroundColor:'black',
            // position:'absolute',
            zIndex:-1,
            overflow:'visible',
            justifyContent:'center',
            objectFit:'fill', 
            height:'200%', 
            width:'200%',
            // bottom:'100%',
            objectPosition:'center',
            position:'fixed',
          }}>
          <Blur radius={(this.state.height>this.state.width)?'40px':'60px'} transition="1ms">
          <img
          src={'https://image.tmdb.org/t/p/w500/pP27zlm9yeKrCeDZLFLP2HKELot.jpg'
          }
          height={'100%'}
          style={{objectFit:'fll', objectPosition:'center'}}
          width={'100%'}
          />
          </Blur>
          </div>

        {(this.state.loading) && (
        <div style={{width:this.state.width, position:'absolute',height:this.state.height, opacity:0.6, zIndex:2, backgroundColor:'black'}}>
        <ThreeCircles
            height={this.state.width/8}
            width={this.state.width/8}
            radius = "9"
            color = '#1167b1ff'
            ariaLabel = 'three-dots-loading'
            wrapperStyle={{marginTop:this.state.height/2-this.state.width/8/2, zIndex:3, marginLeft:this.state.width/2-this.state.width/8/2, position:'absolute'}}
          />
        </div>  
          )}
        <div style={{backgroundColor:'transparent', marginTop:100, marginLeft:100}}>
          <h1 style={{color:'white', fontSize:25}}>{`Total Users: ${this.state.userCount}`}</h1>

          <h1 style={{color:'white', fontSize:25}}>-</h1>

          <h1 style={{color:'white', fontSize:25}}>{`Total Movies:  ${this.state.allMovies.length}`}</h1>
          <h1 style={{color:'white', fontSize:25}}>{`Total Shows:  ${this.state.allShows.length}`}</h1>

          <h1 style={{color:'white', fontSize:25}}>-</h1>

          <h1 style={{color:'white', fontSize:25}}>{`Average Movies Per User:  ${Math.round(this.state.allMovies.length/parseFloat(this.state.userCount))}`}</h1>
          <h1 style={{color:'white', fontSize:25}}>{`Average Shows Per User:  ${Math.round(this.state.allShows.length/parseFloat(this.state.userCount))}`}</h1>
          
          <h1 style={{color:'white', fontSize:25}}>-</h1>

          <h1 style={{color:'white', fontSize:25}}>{`Average Movie Rating Range:  ${Math.round(this.state.movieRatingRange*10)/10}`}</h1>
          <h1 style={{color:'white', fontSize:25}}>{`Average Show Rating Range:  ${Math.round(this.state.showRatingRange*10)/10}`}</h1>
          </div>
        
        {/* <Button onClick={async ()=>await this.addMovie()}>Add</Button> */}
        </div>

      );
    }else{
      return (
        <div>

        </div>
      );
    }

    }
};

export default Developer;
