import { addDoc, collection, collectionGroup, doc, getDocs, limit, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import React from 'react';
import {firestore} from '../firebase'
import { UserAuth } from '../context/AuthContext';
import Cookies from 'js-cookie';
import similarity from 'compute-cosine-similarity'
import { Stack, ImageList, ImageListItem, Button, TextField } from '@mui/material'
import Blur from 'react-css-blur'

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { ThreeCircles } from 'react-loader-spinner';

import '../Sidebar.css'
import { Link } from 'react-router-dom';
import { getColor } from './Main.tsx';

import ReactCardFlip from 'react-card-flip';


type State = {
  loading?:boolean;
  thisUser?:any;
  myMovies?:object;
  myShows?:object;
  myMovieStats?:object;
  myShowStats?:object;
  width?:number;
  height?:number;
  numberOfColumns?:number;
  view?:string;
}

class Statistics extends React.Component<{}, State> {
  constructor(props){
    super(props);

    this.state={
      loading:true,
      thisUser:{},
      myMovies:[],
      myShows:[],
      myMovieStats:[],
      myShowStats:[],
      width:window.innerWidth,
      height:window.innerHeight,
      numberOfColumns:(window.innerHeight>window.innerWidth)?2:5,
      view:'movie'
    }
  }

  async componentDidMount(): void {
    window.addEventListener('resize', this.updateDimensions);
    const userId = localStorage.getItem('userData');

    if (userId == 'undefined' || userId == '112511920029842415746'){
      localStorage.setItem('userData', '112511920029842415746');
      await this.getMyMoviesAndShows();

    }else{
      await this.getMyMoviesAndShows();

    }

    this.setState({loading:false})

  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight});
    this.setState({numberOfColumns:(window.innerHeight>window.innerWidth)?2:5})
    console.log(this.state.width)
    // alert('window updated')
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }


  async getMyMoviesAndShows(){
    await this.getMyMovieStats();
    await this.getMyShowStats();

    this.setState({loading:false});
  }

  async getMyMovieStats(){
    try{
      //GET THIS USER'S FIREBASE DOCUMENT
      const userId = localStorage.getItem('userData');
      const thisUserRef = collection(firestore, "Users");
      const thisUserQuery = query(thisUserRef, where('id', '==',userId));
      const thisUserSnapshot = await getDocs(thisUserQuery);
      const thisUser = thisUserSnapshot.docs[0].data();

      this.setState({thisUser:thisUser})

      var lastMoviesRead = '';

      var allMovies = []

      //GET ALL MY MOVIES
      if (thisUser.readMovies !== undefined && thisUser.readMovies.length>0){
        console.log('FIREBASE READ MOVIES EXISTS')
        //GET ALL MOVIES THAT HAVE CHANGGED SINCE THIS USERS'S LAST READ FROM FIREBASE
        if (Array.isArray(thisUser.readMovies)){
          for (const chunk of thisUser.readMovies){
            allMovies = allMovies.concat(JSON.parse(chunk));
          }
        }else{
          allMovies = JSON.parse(thisUser.readMovies)
        }

        const changedMoviesRef = collectionGroup(firestore, "movie");

        const changedMoviesQuery = query(changedMoviesRef, where('lastModified', '>', Date.parse(thisUser.lastMoviesRead)));

        const changedMoviesSnapshot = await getDocs(changedMoviesQuery);

        changedMoviesSnapshot.forEach((doc) => {
          const itemToPush = {userId:doc.ref.parent.parent.id, data:doc.data()}
          const itemInReadMovies = allMovies.find(i=>i.data.id===itemToPush.data.id && i.userId===itemToPush.userId);

          //REMOVE ITEM IN READ
          if (itemInReadMovies){
            allMovies = allMovies.filter(i=>i!=itemInReadMovies);

          }
          //PUSH NEW VERSION
          allMovies.push(itemToPush);
        });
      }
      var myMovies = this.deepClone(allMovies).filter(b=>b.userId==thisUser.id).filter(c=>c.data.deleted==false).filter(m=>parseFloat(m.data.rating) > 0);

      myMovies = myMovies.sort(function(a, b) {
        return parseFloat(a.data.rating) - parseFloat(b.data.rating);
      }).reverse();

      this.setState({myMovies:myMovies})

      //GET MY MOVIE STATS
      const bestMovieStat = myMovies[0].data;
      const worstMovieStat = myMovies[myMovies.length-1].data;
      const numberOfMoviesStat = myMovies.length;

      //Average rating
      const averageMovieRatingStat = Math.round(myMovies.reduce(function(prev, current) {
        return prev + +current.data.rating
      }, 0)/myMovies.length * 10)/10;

      //Get Average Algorithm Accuracy
      var sumOfAccuracies = 0;
      var numberOfAccuracies = 0;

      var sumOfTMDBAccuracies = 0;
      var numberOfTMDBAccuracies = 0;
      var genreCountList = [];
      for (const movie of myMovies){
        const accuracy = Math.min(parseFloat(movie.data.predicted), parseFloat(movie.data.rating))/Math.max(parseFloat(movie.data.predicted), parseFloat(movie.data.rating))
        if (accuracy){
          sumOfAccuracies += accuracy
          numberOfAccuracies++;
        }
        const tmdbAccuracy = Math.min(parseFloat(movie.data.tmdbRating), parseFloat(movie.data.rating))/Math.max(parseFloat(movie.data.rating), parseFloat(movie.data.tmdbRating))
        if (tmdbAccuracy){
          sumOfTMDBAccuracies += tmdbAccuracy
          numberOfTMDBAccuracies++;
        }
        if (movie.data.genres!=undefined){
          for (var genre of movie.data.genres){
            genre = genre.toString()
              .replace(28,'Action')
              .replace(12,'Adventure')
              .replace(16,'Animation')
              .replace(35,'Comedy')
              .replace(80,'Crime')
              .replace(99,'Documentary')
              .replace(18,'Drama')
              .replace(10751,'Family')
              .replace(14,'Fantasy')
              .replace(36,'History')
              .replace(27,'Horror')
              .replace(10402,'Music')
              .replace(9648,'Mystery')
              .replace(10749,'Romance')
              .replace(878,'Science Fiction')
              .replace(10770,'Tv Movie')
              .replace(53,'Thriller')
              .replace(10752,'War')
              .replace(37,'Western')

            if (genreCountList.includes(genre)){
              //UPDATE GENRE
              const foundGenre = genreCountList.find(g=>g.genreId==genre);
              genreCountList[genreCountList.indexOf(foundGenre)].totalGenrePoints += movie.data.rating;
              genreCountList[genreCountList.indexOf(foundGenre)].genreCount += 1;
            }else{
              //ADD GENRE
              genreCountList.push({
                genreId:genre,
                totalGenrePoints:movie.data.rating,
                genreCount:1,
              })
            }
          }
        }
      }
      const moviesAccuracyStat = (Math.round((sumOfAccuracies/numberOfAccuracies)* 100) / 1)+'%';
      const moviesTMDBAccuracyStat = (Math.round((sumOfTMDBAccuracies/numberOfTMDBAccuracies)* 100) / 1)+'%';

      genreCountList = genreCountList.sort(function(a, b) {
        return parseFloat(a.totalGenrePoints/a.genreCount) - parseFloat(b.totalGenrePoints/b.genreCount)
      }).reverse();

      const bestGenreStat = genreCountList[0];
      const worstGenreStat = genreCountList[genreCountList.length-1];

      const movieStats = [
        {
          statType:'image',
          statTitle:'Your Best Movie',
          statResult:bestMovieStat,
          isFlipped:false,
          color:bestMovieStat.rating/10,
        },
        {
          statType:'image',
          statTitle:'Your Worst Movie',
          statResult:worstMovieStat,
          isFlipped:false,
          color:worstMovieStat.rating/10,
        },
        {
          statType:'text',
          statTitle:'Your Average Movie Rating',
          statResult:averageMovieRatingStat,
          isFlipped:false,
          color:averageMovieRatingStat/10,
        },
        {
          statType:'text',
          statTitle:'Your Movie Prediction Accuracy',
          statResult:moviesAccuracyStat,
          isFlipped:false,
          color:sumOfAccuracies/numberOfAccuracies,
        },
        {
          statType:'text',
          statTitle:'Your Movie Prediction Accuracy From Other Sources',
          statResult:moviesTMDBAccuracyStat,
          isFlipped:false,
          color:sumOfTMDBAccuracies/numberOfTMDBAccuracies,
        },
        {
          statType:'text',
          statTitle:'Your Best Movie Genre',
          statResult:(bestGenreStat!=undefined)?bestGenreStat.genreId:'No Data',
          isFlipped:false,
          color:(bestGenreStat!=undefined)?(bestGenreStat.totalGenrePoints/bestGenreStat.genreCount)/10:'No Data',
        },
        {
          statType:'text',
          statTitle:'Your Worst Movie Genre',
          statResult:(worstGenreStat!=undefined)?worstGenreStat.genreId:'No Data',
          isFlipped:false,
          color:(worstGenreStat!=undefined)?(worstGenreStat.totalGenrePoints/worstGenreStat.genreCount)/10:'No Data',
        },
      ]



      this.setState({myMovieStats:movieStats})
    }catch (e){
      console.log(e)
    }
  }

  async getMyShowStats(){
    try{
      //GET THIS USER'S FIREBASE DOCUMENT
      const userId = localStorage.getItem('userData');
      const thisUserRef = collection(firestore, "Users");
      const thisUserQuery = query(thisUserRef, where('id', '==',userId));
      const thisUserSnapshot = await getDocs(thisUserQuery);
      const thisUser = thisUserSnapshot.docs[0].data();

      this.setState({thisUser:thisUser})

      var allShows = []

      //GET ALL MY MOVIES
      if (thisUser.readShows !== undefined && thisUser.readShows.length>0){
        console.log('FIREBASE READ MOVIES EXISTS')
        //GET ALL MOVIES THAT HAVE CHANGGED SINCE THIS USERS'S LAST READ FROM FIREBASE
        if (Array.isArray(thisUser.readShows)){
          for (const chunk of thisUser.readShows){
            allShows = allShows.concat(JSON.parse(chunk));
          }
        }else{
          allShows = JSON.parse(thisUser.readShows)
        }

        const changedShowsRef = collectionGroup(firestore, "tv");

        const changedShowsQuery = query(changedShowsRef, where('lastModified', '>', Date.parse(thisUser.lastShowsRead)));

        const changedShowsSnapshot = await getDocs(changedShowsQuery);

        changedShowsSnapshot.forEach((doc) => {
          const itemToPush = {userId:doc.ref.parent.parent.id, data:doc.data()}
          const itemInReadShows = allShows.find(i=>i.data.id===itemToPush.data.id && i.userId===itemToPush.userId);

          //REMOVE ITEM IN READ
          if (itemInReadShows){
            allShows = allShows.filter(i=>i!=itemInReadShows);

          }
          //PUSH NEW VERSION
          allShows.push(itemToPush);
        });
      }
      var myShows = this.deepClone(allShows).filter(b=>b.userId==thisUser.id).filter(c=>c.data.deleted==false).filter(m=>parseFloat(m.data.rating) > 0);

      myShows = myShows.sort(function(a, b) {
        return parseFloat(a.data.rating) - parseFloat(b.data.rating);
      }).reverse();

      this.setState({myShows:myShows})

      //GET MY MOVIE STATS
      const bestShowStat = myShows[0].data;
      const worstShowStat = myShows[myShows.length-1].data;
      const numberOfShowsStat = myShows.length;

      //Average rating
      const averageShowRatingStat = Math.round(myShows.reduce(function(prev, current) {
        return prev + +current.data.rating
      }, 0)/myShows.length * 10)/10;

      //Get Average Algorithm Accuracy
      var sumOfAccuracies = 0;
      var numberOfAccuracies = 0;

      var sumOfTMDBAccuracies = 0;
      var numberOfTMDBAccuracies = 0;

      var genreCountList = [];
      for (const show of myShows){
        const accuracy = Math.min(parseFloat(show.data.predicted), parseFloat(show.data.rating))/Math.max(parseFloat(show.data.predicted), parseFloat(show.data.rating))
        if (accuracy){
          sumOfAccuracies += accuracy
          numberOfAccuracies++;
        }
        const tmdbAccuracy = Math.min(parseFloat(show.data.tmdbRating), parseFloat(show.data.rating))/Math.max(parseFloat(show.data.tmdbRating), parseFloat(show.data.rating))
        if (tmdbAccuracy){
          sumOfTMDBAccuracies += tmdbAccuracy
          numberOfTMDBAccuracies++;
        }
        if (show.data.genres!=undefined){
          for (var genre of show.data.genres){
            genre = genre.toString()
              .replace(10759,'Action & Adventure')
              .replace(16,'Animation')
              .replace(35,'Comedy')
              .replace(80,'Crime')
              .replace(99,'Documentary')
              .replace(18,'Drama')
              .replace(10751,'Family')
              .replace(10762,'Kids')
              .replace(9648,'Mystery')
              .replace(10763,'News')
              .replace(10764,'Reality')
              .replace(10765,'Sci-Fi & Fantasy')
              .replace(10766,'Soap')
              .replace(10767,'Talk')
              .replace(10402,'Music')
              .replace(10768,'War & Politics')
              .replace(37,'Western')

            if (genreCountList.includes(genre)){
              //UPDATE GENRE
              const foundGenre = genreCountList.find(g=>g.genreId==genre);
              genreCountList[genreCountList.indexOf(foundGenre)].totalGenrePoints += show.data.rating;
              genreCountList[genreCountList.indexOf(foundGenre)].genreCount += 1;
            }else{
              //ADD GENRE
              genreCountList.push({
                genreId:genre,
                totalGenrePoints:show.data.rating,
                genreCount:1,
              })
            }
          }
        }
      }
      const showsAccuracyStat = (Math.round((sumOfAccuracies/numberOfAccuracies)* 100) / 1)+'%';
      const showsTMDBAccuracyStat = (Math.round((sumOfTMDBAccuracies/numberOfTMDBAccuracies)* 100) / 1)+'%';
      genreCountList = genreCountList.sort(function(a, b) {
        return parseFloat(a.totalGenrePoints/a.genreCount) - parseFloat(b.totalGenrePoints/b.genreCount)
      }).reverse();

      const bestGenreStat = genreCountList[0];
      const worstGenreStat = genreCountList[genreCountList.length-1];

      const showStats = [
        {
          statType:'image',
          statTitle:'Your Best Show',
          statResult:bestShowStat,
          isFlipped:false,
          color:bestShowStat.rating/10,
        },
        {
          statType:'image',
          statTitle:'Your Worst Show',
          statResult:worstShowStat,
          isFlipped:false,
          color:worstShowStat.rating/10,
        },
        {
          statType:'text',
          statTitle:'Your Average Show Rating',
          statResult:averageShowRatingStat,
          isFlipped:false,
          color:averageShowRatingStat/10,
        },
        {
          statType:'text',
          statTitle:'Your Show Prediction Accuracy',
          statResult:showsAccuracyStat,
          isFlipped:false,
          color:sumOfAccuracies/numberOfAccuracies,
        },
        {
          statType:'text',
          statTitle:'Your Show Prediction Accuracy From Other Sources',
          statResult:showsTMDBAccuracyStat,
          isFlipped:false,
          color:sumOfTMDBAccuracies/numberOfTMDBAccuracies,
        },
        {
          statType:'text',
          statTitle:'Your Best Show Genre',
          statResult:(bestGenreStat!=undefined)?bestGenreStat.genreId:'No Data',
          isFlipped:false,
          color:(bestGenreStat!=undefined)?(bestGenreStat.totalGenrePoints/bestGenreStat.genreCount)/10:'No Data',
        },
        {
          statType:'text',
          statTitle:'Your Worst Show Genre',
          statResult:(worstGenreStat!=undefined)?worstGenreStat.genreId:'No Data',
          isFlipped:false,
          color:(worstGenreStat!=undefined)?(worstGenreStat.totalGenrePoints/worstGenreStat.genreCount)/10:'No Data',
        },
      ]

      this.setState({myShowStats:showStats})
    }catch (e){
      console.log(e)
    }
  }

  deepClone(obj, hash = new WeakMap()) {
    // Do not try to clone primitives or functions
    if (Object(obj) !== obj || obj instanceof Function) return obj;
    if (hash.has(obj)) return hash.get(obj); // Cyclic reference
    try { // Try to run constructor (without arguments, as we don't know them)
        var result = new obj.constructor();
    } catch(e) { // Constructor failed, create object without running the constructor
        result = Object.create(Object.getPrototypeOf(obj));
    }
    // Optional: support for some standard constructors (extend as desired)
    if (obj instanceof Map)
        Array.from(obj, ([key, val]) => result.set(this.deepClone(key, hash), 
                                                   this.deepClone(val, hash)) );
    else if (obj instanceof Set)
        Array.from(obj, (key) => result.add(this.deepClone(key, hash)) );
    // Register in hash    
    hash.set(obj, result);
    // Clone and assign enumerable own properties recursively
    return Object.assign(result, ...Object.keys(obj).map (
        key => ({ [key]: this.deepClone(obj[key], hash) }) ));
  }

  getItemsToRender(){
    if (this.state.view=='movie'){
      return this.state.myMovieStats;
    }else{
      return this.state.myShowStats;
    }
  }

  getStatToRender(item){
    if (item.statType=='image'){
      return (
        <ImageListItem key={item.statResult.image} sx={{width:(this.state.width*0.95)/this.state.numberOfColumns+0.15, height:((this.state.width*0.95)/this.state.numberOfColumns+0.15)*1.54, borderTopRightRadius:8,borderTopLeftRadius:8, alignSelf:'center'}}>
        <div onClick={()=>{
          if (this.state.view=='movie'){
            let movieStats = this.state.myMovieStats;
            let statToFlip = movieStats.indexOf(item);
            movieStats[statToFlip].isFlipped = !item.isFlipped;
            this.setState({myMovieStats:movieStats});
          }else{
            let showStats = this.state.myShowStats;
            let statToFlip = showStats.indexOf(item);
            showStats[statToFlip].isFlipped = !item.isFlipped;
            this.setState({myShowStats:showStats});
          }
          }}  
          style={{cursor:'pointer',}}>
          <div style={{width:((this.state.height*0.6)/1.54)*0.2,height:40,zIndex:1, backgroundColor:getColor(item.statResult.rating/10), position:'absolute', borderTopLeftRadius:8, borderBottomRightRadius:8, justifyContent:'center', display:'flex', alignItems:'center'}}>
            <h1 style={{alignSelf:'center', color:'white', fontSize:'25px', textAlign:'center', verticalAlign:'center', marginTop:(40/2)-(25/2)}}>{Math.round(item.statResult.rating * 10) / 10}</h1>
          </div>
          <img  src={item.statResult.image} alt={item.statResult.id} loading={"lazy"} style={{borderRadius:8}}/>
        </div>
    </ImageListItem>
      );
    }else if (item.statType=='text'){
      return (
        <ImageListItem key={item.statResult} sx={{width:(this.state.width*0.95)/this.state.numberOfColumns+0.15, height:((this.state.width*0.95)/this.state.numberOfColumns+0.15)*1.54, borderRadius:8, alignSelf:'center', backgroundColor:'black'}}>
        <div onClick={()=>{
          if (this.state.view=='movie'){
            let movieStats = this.state.myMovieStats;
            let statToFlip = movieStats.indexOf(item);
            movieStats[statToFlip].isFlipped = !item.isFlipped;
            this.setState({myMovieStats:movieStats});
          }else{
            let showStats = this.state.myShowStats;
            let statToFlip = showStats.indexOf(item);
            showStats[statToFlip].isFlipped = !item.isFlipped;
            this.setState({myShowStats:showStats});
          }
        }} 
        style={{cursor:'pointer',}}>
          <div style={{width:(this.state.width*0.95)/this.state.numberOfColumns+0.15,height:((this.state.width*0.95)/this.state.numberOfColumns+0.15)*1.54,zIndex:1, backgroundColor:getColor(item.color), position:'absolute', borderRadius:8, justifyContent:'center', display:'flex', alignItems:'center'}}>
            <h1 style={{alignSelf:'center', color:'white', fontSize:'25px', textAlign:'center', verticalAlign:'center', marginTop:(40/2)-(25/2)}}>{item.statResult}</h1>
          </div>
          <img  src={null} alt={item.statResult} loading={"lazy"} style={{borderRadius:8,width:(this.state.width*0.95)/this.state.numberOfColumns+0.15, height:((this.state.width*0.95)/this.state.numberOfColumns+0.15)*1.54,borderRadius:8}}/>
        </div>
      </ImageListItem>
      );

    }

  }

  render(): React.ReactNode {
      return (
        <div style={{overflow:'hidden'}}>
          <div style={{
            backgroundColor:'black',
            // position:'absolute',
            zIndex:-1,
            overflow:'visible',
            justifyContent:'center',
            objectFit:'fill', 
            height:'200%', 
            width:'200%',
            // bottom:'100%',
            objectPosition:'center',
            position:'fixed',
          }}>
          <Blur radius={(this.state.height>this.state.width)?'40px':'60px'} transition="1ms">
          <img
          src={
            (this.state.view=='movie')?((this.state.myMovies.length>0)?this.state.myMovies[0].data.image:'https://image.tmdb.org/t/p/w500/pP27zlm9yeKrCeDZLFLP2HKELot.jpg'):((this.state.myShows.length>0)?this.state.myShows[0].data.image:'https://image.tmdb.org/t/p/w500/pP27zlm9yeKrCeDZLFLP2HKELot.jpg'
            )

          }
          height={'100%'}
          style={{objectFit:'fll', objectPosition:'center'}}
          width={'100%'}
          />

          </Blur>
          </div>
          
          {(this.state.loading) && (
        <div style={{width:this.state.width, position:'absolute',height:this.state.height, opacity:0.6, zIndex:2, backgroundColor:'black'}}>
        <ThreeCircles
            height={this.state.width/8}
            width={this.state.width/8}
            radius = "9"
            color = '#1167b1ff'
            ariaLabel = 'three-dots-loading'
            wrapperStyle={{marginTop:this.state.height/2-this.state.width/8/2, zIndex:3, marginLeft:this.state.width/2-this.state.width/8/2, position:'absolute'}}
          />
        </div>  
          )}

          {(this.state.myMovieStats.length>0 || this.state.myShowStats.length>0) && ( 
            <Button style={{width:this.state.width,position:'absolute', display:'flex', alignSelf:'center', bottom:0, color:'white'}} onClick={()=>this.setState({currentPageIndex:this.state.currentPageIndex+1})}>More</Button>

          )}
        <div style={{
          justifyContent:'center', 
          width:this.state.width,
          height:this.state.height, 
          backgroundColor:'transparent',
          overflow:'hidden',
          }}>

          {(this.state.myMovieStats.length>0 || this.state.myShowStats.length>0) && (
          <div style={{width:this.state.width, alignSelf:'center', justifyContent:'center', display:'flex', marginTop:this.state.height*0.15, overflow:'none'}}>
          <div style={{
            width:this.state.width, 
            position:'absolute',
            backgroundColor:'transparent', 
            height:this.state.height*0.05, 
            top:this.state.height*0.1-10, 
            overflow:'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf:'center',
            alignContent:'center',
            flexDirection:'row',
            display:'flex'
            }}>
              <label onClick={()=>this.setState({view:'movie'})} style={{marginRight:20, color:(this.state.view=='movie')?'white':'gray'}} className='text-left text-1xl font-bold menu-item'>Movies</label>
            <label onClick={()=>this.setState({view:'tv'})} style={{marginLeft:20, color:(this.state.view=='tv')?'white':'gray'}} className='text-left text-1xl font-bold menu-item'>Shows</label>


          </div>

          <Stack spacing={4} style={{alignSelf:'center', justifyContent:'center',display:'flex', marginTop:10}}>
            <ImageList sx={{width:this.state.width*0.98, height:this.state.height*0.85-10-this.state.height*0.05,justifyContent:'center', alignSelf:'center', overflowX:'hidden'}} cols={this.state.numberOfColumns}>
                {this.getItemsToRender().map(item => (
                  <div>
                      <ReactCardFlip isFlipped={item.isFlipped} flipDirection="vertical" containerStyle={{backgroundColor:'transparent'}}>
                        <ImageListItem key={item.statResult.image} sx={{width:(this.state.width*0.95)/this.state.numberOfColumns+0.15, height:((this.state.width*0.95)/this.state.numberOfColumns+0.15)*1.54, borderRadius:8, alignSelf:'center', backgroundColor:'transparent'}}>
                          <div onClick={()=>{
                            if (this.state.view=='movie'){
                              let movieStats = this.state.myMovieStats;
                              let statToFlip = movieStats.indexOf(item);
                              movieStats[statToFlip].isFlipped = !item.isFlipped;
                              this.setState({myMovieStats:movieStats});
                            }else{
                              let showStats = this.state.myShowStats;
                              let statToFlip = showStats.indexOf(item);
                              showStats[statToFlip].isFlipped = !item.isFlipped;
                              this.setState({myShowStats:showStats});
                            }
                          }} 
                          style={{cursor:'pointer', borderRadius:8, }}>
                            <div style={{backgroundColor:'transparent',width:(this.state.width*0.95)/this.state.numberOfColumns+0.15,height:((this.state.width*0.95)/this.state.numberOfColumns+0.15)*1.54,zIndex:1, backgroundColor:getColor(item.color), position:'absolute', borderRadius:8, justifyContent:'center', display:'flex', alignItems:'center'}}>
                              <h1 aria-disabled style={{alignSelf:'center', color:'white', fontSize:'25px', textAlign:'center', verticalAlign:'center', marginTop:(40/2)-(25/2), borderRadius:8, }}>{item.statTitle}</h1>
                            </div>
                            <img  src={null} alt={item.statResult.id} loading={"lazy"} style={{width:(this.state.width*0.95)/this.state.numberOfColumns+0.15, height:((this.state.width*0.95)/this.state.numberOfColumns+0.15)*1.54,borderRadius:8, backgroundColor:'transparent'}}/>
                          </div>
                        </ImageListItem>

                      {this.getStatToRender(item)}

                      </ReactCardFlip>
                </div>

                ))}
                
            </ImageList>
        </Stack>
        
          </div>
          )}

          {(this.state.myMovieStats.length==0 && !this.state.loading && this.state.view=='movie') && (
            <div className='max-w-[240px] m-auto py-4'>
              <h1 className='text-center font-bold' style={{color:'white', textDecoration:'none',fontSize:this.state.height/22,marginTop:this.state.height/2-this.state.height/22/2-this.state.height*0.1}}>No Stats Available</h1>
          </div>
          )}

          {(this.state.myShowStats.length==0 && !this.state.loading && this.state.view=='tv') && (
            <div className='max-w-[240px] m-auto py-4'>
              <h1 className='text-center font-bold' style={{color:'white', textDecoration:'none',fontSize:this.state.height/22,marginTop:this.state.height/2-this.state.height/22/2-this.state.height*0.1}}>No Stats Available</h1>
          </div>
          )}

        </div>
        </div>

      );
  }

};

export default Statistics;
