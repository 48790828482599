import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Protected from './components/Protected';
import { AuthContextProvider } from './context/AuthContext';
import Account from './pages/Account.tsx';
import Signin from './pages/Signin';
import Main from './pages/Main.tsx'
import Settings from './pages/Settings';
import Search from './pages/Search.tsx';
import Statistics from './pages/Statistics.tsx';
import About from './pages/About.tsx';
import Developer from './pages/Developer.tsx';
import Feedback from './pages/Feedback.tsx';

function App() {
  return (
    <div>
      <AuthContextProvider>
        <Navbar />
        <Routes>
          <Route exact path='/' element={<Signin />} />

          <Route path='/signin' element={<Signin />} />
{/* 
          <Route
            path='/account'
            element={
              <Protected>
                <Account />
              </Protected>
            }
          /> */}
          <Route
            path='/account'
            element={<Account />}
            />
          <Route
            path='/search'
            element={
              // <Protected>
                <Search />
              // </Protected>
            }
          />
          <Route
            path='/main'
            element={
                <Main />
            }
          />
          <Route
            path='/statistics'
            element={
              // <Protected>
                <Statistics />
              // </Protected>
            }
          />
          {/* <Route
            path='/feedback'
            element={
              <Protected>
                <Feedback />
              </Protected>
            }
          /> */}
          <Route
            path='/developer'
            element={
              <Protected>
                <Developer />
              </Protected>
            }
          />
          <Route path='/about' element={<About />} />

        </Routes>
      </AuthContextProvider>
    </div>
  );
}

export default App;
