import { addDoc, collection, collectionGroup, doc, getDocs, limit, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import React from 'react';
import {firestore} from '../firebase'
import { UserAuth } from '../context/AuthContext';
import Cookies from 'js-cookie';
import similarity from 'compute-cosine-similarity'
import { Stack, ImageList, ImageListItem, Button, TextField } from '@mui/material'
import Blur from 'react-css-blur'

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { ThreeCircles } from 'react-loader-spinner';

import '../Sidebar.css'
import { Link } from 'react-router-dom';

type State = {
  loading?:boolean;
  thisUser?:any;
  myMovies?:object;
  myShows?:object;
  currentPageIndex?:number;
  sliderValue?:number;
  selectedItem?:number;
  width?:number;
  height?:number;
  numberOfColumns?:number;
  view?:string;
}

export function getColor(pct) {
  var percentColors = [
    { pct: 0.0, color: { r: 0xde, g: 0x37, b: 0x00 } },
    { pct: 0.5, color: { r: 0xff, g: 0x83, b: 0x03 } },
    { pct: 1.0, color: { r: 0x2a, g: 0xa1, b: 0x0f } } ];

    for (var i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
          break;
      }
    }
    var lower = percentColors[i - 1];
    var upper = percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return rgbToHex(color.r, color.g, color.b)
    //return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
    // or output as hex if preferred
}
function rgbToHex(red, green, blue) {
  const rgb = (red << 16) | (green << 8) | (blue << 0);
  return '#' + (0x1000000 + rgb).toString(16).slice(1);
}

class Account extends React.Component<{}, State> {
  constructor(props){
    super(props);

    this.state={
      loading:true,
      thisUser:{},
      myMovies:[],
      myShows:[],
      currentPageIndex:1,
      sliderValue:5,
      selectedItem:undefined,
      width:window.innerWidth,
      height:window.innerHeight,
      numberOfColumns:(window.innerHeight>window.innerWidth)?2:5,
      view:'movie'
    }
  }

  async componentDidMount(): void {
    window.addEventListener('resize', this.updateDimensions);

    const userId = localStorage.getItem('userData');

    if (userId == 'undefined' || userId == '112511920029842415746' || userId == null || userId == undefined){
      localStorage.setItem('userData', '112511920029842415746');
      await this.getOrSignUpUser();
      await this.getMyMoviesAndShows();
    }else{
      await this.getOrSignUpUser();
      await this.getMyMoviesAndShows();
    }

    this.setState({loading:false})

  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight});
    this.setState({numberOfColumns:(window.innerHeight>window.innerWidth)?2:5})
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  async getOrSignUpUser(){
    const userId = localStorage.getItem('userData');
    const userName = localStorage.getItem('userData_name');
    const userEmail = localStorage.getItem('userData_email');

    try{
      //GET THIS USER'S FIREBASE DOCUMENT
      const thisUserRef = collection(firestore, "Users");
      const thisUserQuery = query(thisUserRef, where('id', '==',userId));
      const thisUserSnapshot = await getDocs(thisUserQuery);
      const thisUser = thisUserSnapshot.docs[0].data();
    }catch (e){
      console.log(e)
      var date = new Date();
      var dateStr =
      ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
      ("00" + date.getDate()).slice(-2) + "/" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2) + ":" +
      ("00" + date.getSeconds()).slice(-2);
      
      //ADD USER TO FIREBASE
      const addUserRef = collection(firestore, 'Users');
      await setDoc(doc(addUserRef, userId), {
        id:userId,
        name:userName,
        email:userEmail,
        username:userEmail.split('@')[0],
        lastMoviesRead:dateStr,
        lastShowsRead:dateStr,
        lastProfileRead:dateStr,
        isNewUser:true,
      });
    }
  }

  async getMyMoviesAndShows(){
    await this.getMyMovies();
    await this.getMyShows();

    this.setState({selectedItem:null, loading:false});
  }

  async getMyMovies(){
    try{
      //GET THIS USER'S FIREBASE DOCUMENT
      const userId = localStorage.getItem('userData');
      const thisUserRef = collection(firestore, "Users");
      const thisUserQuery = query(thisUserRef, where('id', '==',userId));
      const thisUserSnapshot = await getDocs(thisUserQuery);
      const thisUser = thisUserSnapshot.docs[0].data();

      this.setState({thisUser:thisUser})

      var lastMoviesRead = '';

      var allMovies = []

      if (thisUser.readMovies !== undefined && thisUser.readMovies.length>0){
        //console.log('FIREBASE READ MOVIES EXISTS')
        //GET ALL MOVIES THAT HAVE CHANGGED SINCE THIS USERS'S LAST READ FROM FIREBASE
        if (Array.isArray(thisUser.readMovies)){
          for (const chunk of thisUser.readMovies){
            allMovies = allMovies.concat(JSON.parse(chunk));
          }
        }else{
          allMovies = JSON.parse(thisUser.readMovies)
        }

        const changedMoviesRef = collectionGroup(firestore, "movie");

        const changedMoviesQuery = query(changedMoviesRef, where('lastModified', '>', Date.parse(thisUser.lastMoviesRead)));

        const changedMoviesSnapshot = await getDocs(changedMoviesQuery);

        changedMoviesSnapshot.forEach((doc) => {
          const itemToPush = {userId:doc.ref.parent.parent.id, data:doc.data()}
          const itemInReadMovies = allMovies.find(i=>i.data.id===itemToPush.data.id && i.userId===itemToPush.userId);

          //REMOVE ITEM IN READ
          if (itemInReadMovies){
            allMovies = allMovies.filter(i=>i!=itemInReadMovies);

          }
          //PUSH NEW VERSION
          allMovies.push(itemToPush);
        });
      }else{
        //console.log('NO FIREBASE READ MOVIES');

        const userRef = collection(firestore, "Users");
        const userQuery = query(userRef, where('lastMoviesRead','!=',thisUser.lastMoviesRead),orderBy('lastMoviesRead', 'desc'), limit(1));
        const userSnapshot = await getDocs(userQuery);
        const latestReadUser = userSnapshot.docs[0].data();

        if (latestReadUser.readMovies !=undefined){
          //console.log('getting latest read user movies')
          if (Array.isArray(latestReadUser.readMovies)){
            for (const chunk of latestReadUser.readMovies){
              allMovies = allMovies.concat(JSON.parse(chunk));
            }
          }else{
            allMovies = JSON.parse(latestReadUser.readMovies)
          }

          lastMoviesRead = latestReadUser.lastMoviesRead;
        }else{
          //console.log('could not get latest read user movies')
          //NO LOCAL MOVIES EXIST SO GET ALL MOVIES FROM FIREBASE
          const allMoviesRef = collectionGroup(firestore, "movie");

          const allMoviesQuery = query(allMoviesRef);

          const allMoviesSnapshot = await getDocs(allMoviesQuery);

          allMoviesSnapshot.forEach((doc) => {
            const itemToPush = {userId:doc.ref.parent.parent.id, data:doc.data()}

            allMovies.push(itemToPush);
          });
        }
      }

      //RE UPDATE READ MOVIES
      var date = new Date();
      var dateStr =
      ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
      ("00" + date.getDate()).slice(-2) + "/" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2) + ":" +
      ("00" + date.getSeconds()).slice(-2);

      if (lastMoviesRead!=''){
        dateStr = lastMoviesRead;
      }

      const chunkSize = 200;
      const chunks = [];
      for (let i = 0; i < allMovies.length; i += chunkSize) {
          const chunk = allMovies.slice(i, i + chunkSize);
          chunks.push(JSON.stringify(chunk))
      }

      const updateMyReadMoviesRef = collection(firestore, 'Users');
      await updateDoc(doc(updateMyReadMoviesRef, this.state.thisUser.id), {
        readMovies:chunks,
        lastMoviesRead:dateStr
      });

      //GET MY MOVIES

      var myMovies = this.deepClone(allMovies).filter(b=>b.userId==thisUser.id).filter(c=>c.data.deleted==false);

      var unratedMovies = myMovies.filter(m=>parseFloat(m.data.rating) == 0);
      var ratedMovies = myMovies.filter(m=>parseFloat(m.data.rating) > 0);

      ratedMovies = ratedMovies.sort(function(a, b) {
        return parseFloat(a.data.rating) - parseFloat(b.data.rating);
      }).reverse();

      const finalMovies = unratedMovies.concat(ratedMovies)

      this.setState({selectedItem:null,myMovies:finalMovies, loading:false})
    }catch (e){
      console.log(e)
    }
  }

  async getMyShows(){
    try{
      //GET THIS USER'S FIREBASE DOCUMENT
      const userId = localStorage.getItem('userData');
      const thisUserRef = collection(firestore, "Users");
      const thisUserQuery = query(thisUserRef, where('id', '==',userId));
      const thisUserSnapshot = await getDocs(thisUserQuery);
      const thisUser = thisUserSnapshot.docs[0].data();

      this.setState({thisUser:thisUser})

      var lastShowsRead = '';

      var allShows = []

      if (thisUser.readShows !== undefined && thisUser.readShows.length>0){
        //console.log('FIREBASE READ SHOWS EXISTS')
        //GET ALL SHOWS THAT HAVE CHANGGED SINCE THIS USERS'S LAST READ FROM FIREBASE
        if (Array.isArray(thisUser.readShows)){
          for (const chunk of thisUser.readShows){
            allShows = allShows.concat(JSON.parse(chunk));
          }
        }else{
          allShows = JSON.parse(thisUser.readShows)
        }

        const changedShowsRef = collectionGroup(firestore, "tv");

        const changedShowsQuery = query(changedShowsRef, where('lastModified', '>', Date.parse(thisUser.lastShowsRead)));

        const changedShowsSnapshot = await getDocs(changedShowsQuery);

        changedShowsSnapshot.forEach((doc) => {
          const itemToPush = {userId:doc.ref.parent.parent.id, data:doc.data()}
          const itemInReadShows = allShows.find(i=>i.data.id===itemToPush.data.id && i.userId===itemToPush.userId);

          //REMOVE ITEM IN READ
          if (itemInReadShows){
            allShows= allShows.filter(i=>i!=itemInReadShows);

          }
          //PUSH NEW VERSION
          allShows.push(itemToPush);
        });
      }else{
        //console.log('NO FIREBASE READ Shows');

        const userRef = collection(firestore, "Users");
        const userQuery = query(userRef, where('lastShowsRead','!=',thisUser.lastShowsRead),orderBy('lastShowsRead', 'desc'), limit(1));
        const userSnapshot = await getDocs(userQuery);
        const latestReadUser = userSnapshot.docs[0].data();

        if (latestReadUser.readShows !=undefined){
          //console.log('getting latest read user shows')
          if (Array.isArray(latestReadUser.readShows)){
            for (const chunk of latestReadUser.readShows){
              allShows = allShows.concat(JSON.parse(chunk));
            }
          }else{
            allShows = JSON.parse(latestReadUser.readShows)
          }

          lastShowsRead = latestReadUser.lastShowsRead;

        }else{
          //console.log('could not get latest read user shows')
          //NO LOCAL SHOWS EXIST SO GET ALL SHOW FROM FIREBASE
          const allShowsRef = collectionGroup(firestore, "tv");

          const allShowsQuery = query(allShowsRef);

          const allShowsSnapshot = await getDocs(allShowsQuery);

          allShowsSnapshot.forEach((doc) => {
            const itemToPush = {userId:doc.ref.parent.parent.id, data:doc.data()}

            allShows.push(itemToPush);
          });
        }
      }

      //RE UPDATE READ MOVIES
      var date = new Date();
      var dateStr =
      ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
      ("00" + date.getDate()).slice(-2) + "/" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2) + ":" +
      ("00" + date.getSeconds()).slice(-2);

      if (lastShowsRead != ''){
        dateStr = lastShowsRead;
      }

      const chunkSize = 200;
      const chunks = [];
      for (let i = 0; i < allShows.length; i += chunkSize) {
          const chunk = allShows.slice(i, i + chunkSize);
          chunks.push(JSON.stringify(chunk))
      }

      const updateMyReadShowsRef = collection(firestore, 'Users');
      await updateDoc(doc(updateMyReadShowsRef, this.state.thisUser.id), {
        readShows:chunks,
        lastShowsRead:dateStr
      });

      //GET MY SHOWS
      var myShows= this.deepClone(allShows).filter(b=>b.userId==thisUser.id).filter(c=>c.data.deleted==false);

      var unratedShows = myShows.filter(m=>parseFloat(m.data.rating) == 0);
      var ratedShows= myShows.filter(m=>parseFloat(m.data.rating) > 0);

      ratedShows = ratedShows.sort(function(a, b) {
        return parseFloat(a.data.rating) - parseFloat(b.data.rating);
      }).reverse();

      const finalShows = unratedShows.concat(ratedShows)

      this.setState({selectedItem:null,myShows:finalShows, loading:false})
    }catch (e){
      console.log(e)
    }
  }

  deepClone(obj, hash = new WeakMap()) {
    // Do not try to clone primitives or functions
    if (Object(obj) !== obj || obj instanceof Function) return obj;
    if (hash.has(obj)) return hash.get(obj); // Cyclic reference
    try { // Try to run constructor (without arguments, as we don't know them)
        var result = new obj.constructor();
    } catch(e) { // Constructor failed, create object without running the constructor
        result = Object.create(Object.getPrototypeOf(obj));
    }
    // Optional: support for some standard constructors (extend as desired)
    if (obj instanceof Map)
        Array.from(obj, ([key, val]) => result.set(this.deepClone(key, hash), 
                                                   this.deepClone(val, hash)) );
    else if (obj instanceof Set)
        Array.from(obj, (key) => result.add(this.deepClone(key, hash)) );
    // Register in hash    
    hash.set(obj, result);
    // Clone and assign enumerable own properties recursively
    return Object.assign(result, ...Object.keys(obj).map (
        key => ({ [key]: this.deepClone(obj[key], hash) }) ));
  }

  getItemsToRender(){
    if (this.state.view == 'movie'){
      const itemsToRender = this.deepClone(this.state.myMovies).slice(0,this.state.currentPageIndex*20);
  
      return itemsToRender;
    }else{
      const itemsToRender = this.deepClone(this.state.myShows).slice(0,this.state.currentPageIndex*20);
  
      return itemsToRender;
    }

  }

  async movieClicked(item){
    if (localStorage.getItem('userData') == '112511920029842415746'){
      alert('Sign In To Use This Feature')
      this.setState({sliderValue:item.data.rating})
    }else{
      var date = new Date();
      var dateStr =
      ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
      ("00" + date.getDate()).slice(-2) + "/" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2) + ":" +
      ("00" + date.getSeconds()).slice(-2);

      const rating = Math.round(this.state.sliderValue*100)/100;
      
      var answer = window.confirm("Update?\nWith Rating " + rating);
      if (answer) {
        if (this.state.view=='movie'){
          if (rating==0){
            this.setState({selectedItem:null})
          }
          //UPDATE IN FIREBASE AND REMOVE FROM THIS MOVIES
          const addMovieRef = collection(firestore, 'Users/'+this.state.thisUser.id+'/movie');
          await updateDoc(doc(addMovieRef, item.data.id), {
            rating:rating,
            lastModified:Date.parse(dateStr),
          });

          let myMovies = this.state.myMovies;
          const findMovie = myMovies.find(m=>m.data.id==item.data.id)
          myMovies[myMovies.indexOf(findMovie)].data.rating = rating;

          var unratedMovies = myMovies.filter(m=>parseFloat(m.data.rating) == 0);
          var ratedMovies = myMovies.filter(m=>parseFloat(m.data.rating) > 0);
    
          ratedMovies = ratedMovies.sort(function(a, b) {
            return parseFloat(a.data.rating) - parseFloat(b.data.rating);
          }).reverse();
    
          const finalMovies = unratedMovies.concat(ratedMovies)
    
          this.setState({selectedItem:null,myMovies:finalMovies, loading:false})

        }else if (this.state.view=='tv'){
          if (rating==0){
            this.setState({selectedItem:null})
          }
          //UPDATE IN FIREBASE AND REMOVE FROM THIS SHOWS
          const addShowRef = collection(firestore, 'Users/'+this.state.thisUser.id+'/tv');
          await updateDoc(doc(addShowRef, item.data.id), {
            rating:rating,
            lastModified:Date.parse(dateStr),
          });

          let myShows = this.state.myShows;
          const findShow = myShows.find(s=>s.data.id==item.data.id)
          myShows[myShows.indexOf(findShow)].data.rating = rating;

          var unratedShows = myShows.filter(m=>parseFloat(m.data.rating) == 0);
          var ratedShows = myShows.filter(m=>parseFloat(m.data.rating) > 0);
    
          ratedShows = ratedShows.sort(function(a, b) {
            return parseFloat(a.data.rating) - parseFloat(b.data.rating);
          }).reverse();
    
          const finalShows = unratedShows.concat(ratedShows)
    
          this.setState({selectedItem:null,myShows:finalShows, loading:false})
        }
      }
      else {
        //DO NOT UPDATE IN FIREBASE
        this.setState({sliderValue:item.data.rating})
      }
    }
  }

  async removeMovie(item){
    if (localStorage.getItem('userData') == '112511920029842415746'){
      alert('Sign In To Use This Feature')
    }else{
      var date = new Date();
      var dateStr =
      ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
      ("00" + date.getDate()).slice(-2) + "/" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2) + ":" +
      ("00" + date.getSeconds()).slice(-2);
      
      var answer = window.confirm("Remove?");
      if (answer) {
        if (this.state.view=='movie'){
          //UPDATE IN FIREBASE AND REMOVE FROM THIS MOVIES
          const removeMovieRef = collection(firestore, 'Users/'+this.state.thisUser.id.toString()+'/movie');
          await updateDoc(doc(removeMovieRef, item.data.id.toString()), {
            deleted:true,
            rating:'0',
            lastModified:Date.parse(dateStr),
          });

          let newMovies = this.state.myMovies;
          newMovies = newMovies.filter(m=>m.data.id!=item.data.id);
          this.setState({myMovies:newMovies})

        }else if (this.state.view =='tv'){
          //UPDATE IN FIREBASE AND REMOVE FROM THIS MOVIES
          const removeShowRef = collection(firestore, 'Users/'+this.state.thisUser.id+'/tv');
          await updateDoc(doc(removeShowRef, item.data.id), {
            deleted:true,
            rating:'0',
            lastModified:Date.parse(dateStr),
          });


          let newShows = this.state.myShows;
          newShows = newShows.filter(s=>s.data.id!=item.data.id);
          this.setState({myShows:newShows})
        }

      }
      else {
      }
    }
  }

  render(): React.ReactNode {
      return (
        <div style={{overflow:'hidden'}}>
          <div style={{
            backgroundColor:'black',
            // position:'absolute',
            zIndex:-1,
            overflow:'visible',
            justifyContent:'center',
            objectFit:'fill', 
            height:'200%', 
            width:'200%',
            // bottom:'100%',
            objectPosition:'center',
            position:'fixed',
          }}>
          <Blur radius={(this.state.height>this.state.width)?'40px':'60px'} transition="1ms">
          <img
          src={
            (this.state.view=='movie')?((this.state.myMovies.length>0)?this.state.myMovies[0].data.image:'https://image.tmdb.org/t/p/w500/pP27zlm9yeKrCeDZLFLP2HKELot.jpg'):((this.state.myShows.length>0)?this.state.myShows[0].data.image:'https://image.tmdb.org/t/p/w500/pP27zlm9yeKrCeDZLFLP2HKELot.jpg'
            )

          }
          height={'100%'}
          style={{objectFit:'fll', objectPosition:'center'}}
          width={'100%'}
          />

          </Blur>
          </div>
          
          {(this.state.loading) && (
        <div style={{width:this.state.width, position:'absolute',height:this.state.height, opacity:0.6, zIndex:2, backgroundColor:'black'}}>
        <ThreeCircles
            height={this.state.width/8}
            width={this.state.width/8}
            radius = "9"
            color = '#1167b1ff'
            ariaLabel = 'three-dots-loading'
            wrapperStyle={{marginTop:this.state.height/2-this.state.width/8/2, zIndex:3, marginLeft:this.state.width/2-this.state.width/8/2, position:'absolute'}}
          />
        </div>  
          )}

          {(this.state.myMovies.length>0 || this.state.myShows.length>0) && ( 
            <Button style={{width:this.state.width,position:'absolute', display:'flex', alignSelf:'center', bottom:0, color:'white'}} onClick={()=>this.setState({currentPageIndex:this.state.currentPageIndex+1})}>More</Button>
          )}
        <div style={{
          justifyContent:'center', 
          width:this.state.width,
          height:this.state.height, 
          backgroundColor:'transparent',
          overflow:'hidden',
          }}>

          {(this.state.myMovies.length>0 || this.state.myShows.length>0) && (
          <div style={{width:this.state.width, alignSelf:'center', justifyContent:'center', display:'flex', marginTop:this.state.height*0.15, overflow:'none'}}>
          <div style={{
            width:this.state.width, 
            position:'absolute',
            backgroundColor:'transparent', 
            height:this.state.height*0.05, 
            top:this.state.height*0.1-10, 
            overflow:'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf:'center',
            alignContent:'center',
            flexDirection:'row',
            display:'flex'
            }}>
              <label onClick={()=>this.setState({view:'movie'})} style={{marginRight:20, color:(this.state.view=='movie')?'white':'gray'}} className='text-left text-1xl font-bold menu-item'>Movies</label>
            <label onClick={()=>this.setState({view:'tv'})} style={{marginLeft:20, color:(this.state.view=='tv')?'white':'gray'}} className='text-left text-1xl font-bold menu-item'>Shows</label>


          </div>

          <Stack spacing={4} style={{alignSelf:'center', justifyContent:'center',display:'flex', marginTop:10}}>
            <ImageList sx={{width:this.state.width*0.98, height:this.state.height*0.85-10-this.state.height*0.05,justifyContent:'center', alignSelf:'center', overflowX:'hidden'}} cols={this.state.numberOfColumns}>
                {this.getItemsToRender().map(item => (
                  <div>
                    <ImageListItem key={item.data.image} sx={{width:(this.state.width*0.95)/this.state.numberOfColumns+0.15, height:((this.state.width*0.95)/this.state.numberOfColumns+0.15)*1.54, borderTopRightRadius:8,borderTopLeftRadius:8, alignSelf:'center'}}>
                    <div onClick={()=>this.setState({sliderValue:item.data.rating,selectedItem:item.data.id})} style={{cursor:'pointer',width:((this.state.height*0.6)/1.54)*0.2,height:40,zIndex:1, backgroundColor:(this.state.selectedItem!=undefined&&this.state.selectedItem == item.data.id)?getColor(this.state.sliderValue/10):getColor(item.data.rating/10), position:'absolute', borderTopLeftRadius:8, borderBottomRightRadius:8, justifyContent:'center', display:'flex', alignItems:'center'}}>
                    <h1 style={{alignSelf:'center', color:'white', fontSize:'25px', textAlign:'center', verticalAlign:'center', marginTop:(40/2)-(25/2)}}>{(this.state.selectedItem!=undefined&&this.state.selectedItem == item.data.id)?this.state.sliderValue:(Math.round(item.data.rating * 10) / 10).toString().replace('0','U')}</h1>
                   </div>
                   {(this.state.selectedItem!=undefined&&this.state.selectedItem== item.data.id && !this.state.loading) && (
                    <div style={{width:(this.state.width*0.95)/this.state.numberOfColumns+0.15,height:40,zIndex:3, marginTop:((this.state.width*0.95)/this.state.numberOfColumns+0.15)*1.54-40,backgroundColor:'transparent', position:'absolute', borderTopLeftRadius:20, borderBottomRightRadius:20, justifyContent:'center', display:'flex', alignItems:'center'}}>
                    <Slider 
                    value={this.state.sliderValue}
                    style={{alignSelf:'center', width:(this.state.width*0.85)/this.state.numberOfColumns+0.15, zIndex:3,}}
                    min={0.1} 
                    max={9.9}
                    step={0.1}
                    pushable={false}
                    trackStyle={{backgroundColor:'lightgray', zIndex:3}}
                    railStyle={{backgroundColor:'#444444', zIndex:3}}
                    handleStyle={{backgroundColor:'white', zIndex:3}}
                    onChange={(value:number)=>{this.setState({sliderValue:value})}}
                    onAfterChange={(value: number)=>{
                      this.setState({sliderValue:value});
                      this.movieClicked(item)
                      }}/>
                  
                  </div>
                   )}

                    <img style={{cursor:'pointer'}} onClick={()=>this.removeMovie(item)} src={item.data.image} alt={item.data.id} loading={"lazy"} style={{borderRadius:8}}/>
                        
                   </ImageListItem>
                </div>

                ))}
                
            </ImageList>
        </Stack>
        
          </div>
          )}

          {(this.state.myMovies.length==0 && !this.state.loading && this.state.view=='movie') && (
            <div className='max-w-[240px] m-auto py-4'>
              <h1 className='text-center font-bold' style={{color:'white', textDecoration:'none',fontSize:this.state.height/22,marginTop:this.state.height/2-this.state.height/22/2-this.state.height*0.1}}>No Movies Added</h1>
              <h1 className='text-center font-bold' style={{color:'white', textDecoration:'none',fontSize:this.state.height/28}}>Add some movies from the search page and then come back to rate it</h1>
          </div>
          )}

          {(this.state.myShows.length==0 && !this.state.loading && this.state.view=='tv') && (
            <div className='max-w-[240px] m-auto py-4'>
              <h1 className='text-center font-bold' style={{color:'white', textDecoration:'none',fontSize:this.state.height/22,marginTop:this.state.height/2-this.state.height/22/2-this.state.height*0.1}}>No Shows Added</h1>
              <h1 className='text-center font-bold' style={{color:'white', textDecoration:'none',fontSize:this.state.height/28}}>Add some shows from the search page and then come back to rate it</h1>
          </div>
          )}

        </div>
        </div>

      );
  }

};

export default Account;
