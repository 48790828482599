import logo from '../.assets/logo.png';
import React from 'react';
import { Button, ImageList, ListItemButton, ListItemText, Stack, TextField } from '@mui/material';

import { addDoc, collection, collectionGroup, deleteDoc, doc, getDocs, limit, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';

import {firestore} from '../firebase'
import { ThreeCircles } from 'react-loader-spinner';
import Blur from 'react-css-blur';


type State = {
  loading?:boolean;
  width?:number;
  height?:number;
  feedbackInput?:string;
}

class Feedback extends React.Component<{}, State> {
  constructor(props){
    super(props);
    
    this.state={
      loading:true,
      width:window.innerWidth,
      height:window.innerHeight,
      feedbackInput:'',
    }
  }

  async componentDidMount(): void {
    window.addEventListener('resize', this.updateDimensions);


    this.setState({loading:false});
  }

  async submitFeedback(){
    if (this.state.feedbackInput.length.trim()>0){
      const thisUserEmail = localStorage.getItem('userData_email')
      var date = new Date();
      var dateStr =
      ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
      ("00" + date.getDate()).slice(-2) + "/" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2) + ":" +
      ("00" + date.getSeconds()).slice(-2);

      //UPDATE IN FIREBASE AND REMOVE FROM THIS GOALS
      const addFeedbackRef = collection(firestore, 'Feedback');
      await setDoc(doc(addFeedbackRef, Date.parse(dateStr).toString()), {
        id:Date.parse(dateStr).toString(),
        date:dateStr,
        feedback:this.state.feedbackInput,
        posterEmail:thisUserEmail
      });
      this.setState({feedbackInput:''})
      alert('Thank You For Your Feedback, We Will Respond Via Email')
    }else{
      alert('Enter Some Feedback First')
    }
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight});
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render(){
    if (!this.state.loading) {
      return(
        <div style={{
          overflow:'hidden',
          }}>    
          <div style={{
            backgroundColor:'black',
            // position:'absolute',
            zIndex:-1,
            overflow:'visible',
            justifyContent:'center',
            objectFit:'fill', 
            height:'200%', 
            width:'200%',
            // bottom:'100%',
            objectPosition:'center',
            position:'fixed',
          }}>
          <Blur radius={(this.state.height>this.state.width)?'40px':'60px'} transition="1ms">
          <img
          src={'https://image.tmdb.org/t/p/w500/pP27zlm9yeKrCeDZLFLP2HKELot.jpg'
          }
          height={'100%'}
          style={{objectFit:'fll', objectPosition:'center'}}
          width={'100%'}
          />

          </Blur>
          </div>  
          <h1 style={{color:'lightgray', fontSize:28, width:this.state.width, alignSelf:'center', textAlign:'center', marginTop:50, position:'absolute'}}>Please give any feedback/suggestion that we can use to improve the website</h1>  
          <div style={{
          justifyContent:'center', 
          width:this.state.width,
          height:this.state.height, 
          alignItems:'center',
          justifyContent:'center',
          alignContent:'center',
          display:'flex',
          overflow:'hidden',
          flexDirection:'column'
          }}> 
          <TextField 
           fullWidth
           variant='standard'
           autoFocus={true}
           placeholder='enter your feedback'
           sx={{ input: { color: '#dddddd', fontSize:25, textAlign:'center',backgroundColor:'transparent',fontVariant: ['tabular-nums']} }}
           InputProps={{
             disableUnderline: true,
           }}
           value={this.state.feedbackInput}

           onChange={(e)=>this.setState({feedbackInput:e.target.value})}
           />
           <Button onClick={()=>this.submitFeedback()} style={{alignSelf:'center', display:'flex', marginTop:50, color:'white'}}>Submit Feedback</Button>
           </div>
        </div>


      );
    }
    else{
      return (
        <div style={{justifyContent:'center', overflow:'hidden', height:window.innerHeight, display:'flex', backgroundColor:'black'}}>
        <ThreeCircles
          height={window.innerWidth/8}
          width={window.innerWidth/8}
          radius = "9"
          color = '#1167b1'
          ariaLabel = 'three-dots-loading'     
          wrapperStyle={{marginTop:window.innerHeight/2-window.innerWidth/8/2}}
        />
      </div>
      )
    }
  }
}


export default Feedback;
